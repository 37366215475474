import analysisCard from "../ToolsCard.vue";

import Button from "@/components/button";
import Input from "@/components/input";
import Loader from "@/components/loader";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import axios from "../../../../axios/osint.js";
import SaasAxios from "@/axios/index.js";
import mainAxios from "@/axios";
import {setUsernamesDropdownData, convertToCSV, csvFileName, getMappingValue, getMonitoring} from "@/utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import JsonCSV from "vue-json-csv";
import {ADVERSE_TOOL_MAPPING} from "@/utils/constants";
import NeoPagination from "@/components/pagination";
import {cloneDeep, uniqBy, groupBy, get, isEqual} from "lodash";
import VueMultiselect from "@/components/vue-multiselect";
import toggleSwitch from "@/components/toggle-switch";
import ModalConfirm from "@/components/modal-confirm";
import ResultList from "@/components/result-list";
import ResultExpanded from "@/components/result-expanded";
import uploadButton from "@/components/upload-button";
import AdverseMediaResources from "@/views/research/tools/adverse-media/adverse-media-resources";
import {EventBus} from "@/main.js";
import {monitoringSearchData, monitoringHistoryData} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import runAllQueries from "@/mixins/runAllQueries";
import {DateTime} from "luxon";
import neoSwitch from "@/components/toggle-switch";
import ExpandFilter from "./../ExpandFilter.vue";
import FilterModal from "./../FilterModal.vue";

import {saveToolGraphData} from "../services";

const generateFiltersWrapperModel = (title, src_name, value, filterSections, tagClass, disabled) => ({
    title,
    src_name,
    value,
    filterSections,
    tagClass,
    isChecked: false,
    isModalVisible: false,
    disabled,
});

export default {
    name: "neo-adverse-media",
    /**
     * @mixin
     * @name toolsHelper - mixin to control tools & monitoring actions
     */
    mixins: [toolsHelper, runAllQueries],
    /**
     * @components - external components used in the component
     */
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-loader": Loader,
        "analysis-card": analysisCard,
        "download-csv": JsonCSV,
        "modal-confirm": ModalConfirm,
        NeoPagination,
        VueMultiselect,
        toggleSwitch,
        ResultList,
        ResultExpanded,
        "neo-upload-button": uploadButton,
        AdverseMediaResources,
        ExpandFilter,
        FilterModal,
        neoSwitch,
        // MonitoringFilter
    },
    props: {
        /**
         * @property
         * @name toolData - input data (case data + inputs run throught tools) for input types matching to selected tool
         */
        toolData: {
            type: Array,
            default: () => [],
        },
        /**
         * @property
         * @name tool - selected tool name
         */
        tool: {
            type: String,
            default: "",
        },
        isQueryPanelExpanded: {
            type: Boolean,
            default: false,
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        toolSearchQueries: Array,
        frequency: {
            default: "",
        },
    },
    data: () => ({
        searchItem: "",
        nameSearches: [],
        fNameSearches: [],
        mNameSearches: [],
        lNameSearches: [],
        personSearches: [],
        loaderController: {
            nameSearch: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
        },
        adverseResults: [],
        adverseFilteredResults: [],
        showResult: false,
        showFiltersPanel: false,
        queries: [],
        newEntity: null,
        entityCount: 1,
        selectedItem: null,
        triggerRoute: true,
        selectedResultCard: null,
        isOldVersionSelected: false,
        isResultExpanded: false,
        expandedResultCard: null,
        page_number: 1,
        items_count: 10,
        searchQuery: "",
        sourceType: [],
        filterEntityType: [],
        filterTypes: [],
        filterJurisdiction: [],
        selectVal: [""],
        searchedClicked: false,
        tabName: "",
        allFilters: [],
        peps: true,
        sanction: true,
        adverseMedia: true,
        searchKeyword: "",
        dropValue: "",
        checks: ["peps", "sanctions", "adverse"],
        sources: ["lexis"],
        bulkMonitor: false,
        open: false,
        monitoringStatus: false,
        selected: "",
        inputlength: 0,
        fName: null,
        mName: null,
        lName: null,
        entityType: "person",
        /**
         * @property
         * @name allSources - sources data along with sources filters and title
         *                  - each source data returned through a function generateFiltersWrapperModel
         *                      - containing source title, source name mapping, source value mapping, and filters allowed
         */
        allSources: {
            lexisBridger: generateFiltersWrapperModel(
                "Lexis Bridger",
                "lexis_bridger",
                "lexis",
                [
                    {
                        label: "Identification",
                        person_filters: ["lexisDateOfBirth", "ssn", "nationalId", "citizenship"],
                        company_filters: ["ein"],
                        expanded: false,
                    },
                    {
                        label: "Current Address",
                        person_filters: ["curr_address1", "curr_address2", "curr_city", "curr_state", "curr_postalCode", "curr_lexisCountry"],
                        company_filters: ["curr_address1", "curr_address2", "curr_city", "curr_state", "curr_postalCode", "curr_lexisCountry"],
                        expanded: false,
                    },
                    {
                        label: "Previous Address",
                        person_filters: ["prev_address1", "prev_address2", "prev_city", "prev_state", "prev_postalCode", "prev_lexisCountry"],
                        company_filters: ["prev_address1", "prev_address2", "prev_city", "prev_state", "prev_postalCode", "prev_lexisCountry"],
                        expanded: false,
                    },
                    {
                        label: "Mailing Address",
                        person_filters: ["mailing_address1", "mailing_address2", "mailing_city", "mailing_state", "mailing_postalCode", "mailing_lexisCountry"],
                        company_filters: ["mailing_address1", "mailing_address2", "mailing_city", "mailing_state", "mailing_postalCode", "mailing_lexisCountry"],
                        expanded: false,
                    },
                    {
                        label: "Phone Number",
                        person_filters: ["business", "home", "work", "cell", "fax", "unknown"],
                        company_filters: ["business", "cell", "fax", "unknown"],
                        expanded: false,
                    },
                    {
                        label: "DUNS",
                        // person_filters: ["dunsId", "issuingCountry", "issueDate", "expirationDate"],
                        // company_filters: ["dunsId", "issuingCountry", "issueDate", "expirationDate"],
                        person_filters: ["dunsId"],
                        company_filters: ["dunsId"],
                        expanded: false,
                    },
                ],
                "tw-bg-green-300"
            ),
            complyAdvantage: generateFiltersWrapperModel(
                "Comply Advantage",
                "comply_advantage",
                "complyadvantage",
                [
                    {
                        label: "",
                        filters: ["yearOfBirth", "country", "fuzzinessLevel"],
                        expanded: true,
                    },
                ],
                "tw-bg-blue-100",
                true
            ),
            worldCheck: generateFiltersWrapperModel(
                "World Check",
                "world_check",
                "worldcheck",
                [
                    {
                        label: "",
                        filters: ["searchMode"],
                        expanded: true,
                    },
                ],
                "tw-bg-green-100",
                true
            ),
            djWl: generateFiltersWrapperModel(
                "Dow Jones Watchlist",
                "dow_jones",
                "wl",
                [
                    {
                        label: "",
                        filters: ["country"],
                        expanded: true,
                    },
                ],
                "tw-bg-red-100",
                true
            ),
            djSoe: generateFiltersWrapperModel(
                "DowJones State Owned Entities",
                "dow_jones",
                "soe",
                [
                    {
                        label: "",
                        filters: ["country"],
                        expanded: true,
                    },
                ],
                "tw-bg-gray-100",
                true
            ),
            djAme: generateFiltersWrapperModel(
                "Dow Jones Adverse Media Entities",
                "dow_jones",
                "ame",
                [
                    {
                        label: "",
                        filters: ["country"],
                        expanded: true,
                    },
                ],
                "tw-bg-green-300",
                true
            ),
            // offshoreLeaks: generateFiltersWrapperModel("Offshore Leaks", "", "offshoreleaks", ["searchMode"], "tw-bg-yellow-100"),
        },
        /**
         * @property
         * @name searchMode - searchMode filter data
         */
        searchMode: {
            label: "Search Mode",
            value: {
                name: "Exact Match",
                value: "exact_match",
            },
            class: "tw-w-full",
            isSourceSpecific: true,
            props: {
                options: [
                    {
                        name: "Exact Match",
                        value: "exact_match",
                    },
                    {
                        name: "Part Match",
                        value: "part_match",
                    },
                ],
                // maxHeight: 250,
                placeholder: "Select Search Mode",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
                allowEmpty: false,
                checkboxes: true,
                trackBy: "value",
                label: "name",
            },
        },

        /**
         * @property
         * @name yearOfBirth - yearOfBirth filter data
         */
        yearOfBirth: {
            label: "Year of birth",
            value: null,
            class: "tw-w-1/3",
            isSourceSpecific: true,
            props: {
                searchable: true,
                options: [],
                checkboxes: true,
                closeOnSelect: true,
                showLabels: false,
                maxHeight: 250,
                placeholder: "Select Year",
                tagPlaceholder: "",
            },
        },
        /**
         * Lexis Bridger Filters - Identification
         */
        /**
         * @property
         * @name lexisDateOfBirth - date of birth filter data
         */
        lexisDateOfBirth: {
            label: "DOB",
            value: null,
            class: "custom-cols-4",
            isSourceSpecific: true,
            drops: [
                {
                    value: null,
                    props: {
                        placeholder: "DD",
                        searchable: true,
                        checkboxes: false,
                        closeOnSelect: true,
                        showLabels: false,
                        maxHeight: 250,
                        tagPlaceholder: "",
                    },
                },
                {
                    value: null,
                    props: {
                        placeholder: "MM",
                        searchable: true,
                        checkboxes: false,
                        closeOnSelect: true,
                        showLabels: false,
                        maxHeight: 250,
                        tagPlaceholder: "",
                    },
                },
                {
                    value: null,
                    props: {
                        placeholder: "YYYY",
                        searchable: true,
                        checkboxes: false,
                        closeOnSelect: true,
                        showLabels: false,
                        maxHeight: 250,
                        tagPlaceholder: "",
                    },
                },
            ],
        },
        /**
         * @property
         * @name ein - ein filter data
         */
        ein: {
            label: "EIN",
            value: "",
            type: "string",
            class: "tw-w-full",
            isSourceSpecific: true,
            props: {
                placeholder: "XX-XXXXXXX",
            },
        },
        /**
         * @property
         * @name ssn - ssn filter data
         */
        ssn: {
            label: "SSN",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "XXX-XX-XXXX",
            },
        },
        /**
         * @property
         * @name nationalId - nationalId filter data
         */
        nationalId: {
            label: "National ID",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "National ID",
            },
        },
        /**
         * @property
         * @name citizenship - citizenship filter data
         */
        citizenship: {
            label: "Citizenship",
            value: "",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                options: [],
                groupLabel: "group",
                groupValues: "categories",
                groupSelect: true,
                trackBy: "name",
                label: "name",
                multiple: false,
                limit: 3,
                checkboxes: true,
                placeholder: "Select Country",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
            },
        },
        /**
         * Lexis Bridger Filters - Current Address
         */
        /**
         * @property
         * @name address1 - address1 filter data
         */
        curr_address1: {
            label: "Address Line 1",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Address Line 1",
                maxlength: 100,
            },
        },
        /**
         * @property
         * @name address2 - address2 filter data
         */
        curr_address2: {
            label: "Address Line 2",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Address Line 2",
                maxlength: 100,
            },
        },
        /**
         * @property
         * @name city - city filter data
         */
        curr_city: {
            label: "City",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "City",
                maxlength: 50,
            },
        },
        /**
         * @property
         * @name state - state filter data
         */
        curr_state: {
            label: "State",
            value: null,
            props: {
                searchable: true,
                // options: [ "Alabama", "Alaska", "Alberta", "American Samoa", "Arizona", "Arkansas", "British Columbia", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Federated States of Micronesia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Manitoba", "Marshall Islands", "Maryland", "Massachusetts", "Michigan", "Military Identification", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Brunswick", "New Hampshire", "New Jersey", "New Mexico", "New York", "Newfoundland", "Newfoundland and Labrador", "North Carolina", "North Dakota", "Northern Mariana Islands", "Northwest Territories", "Oregon", "Palau", "Pennsylvania", "Prince Edward Island", "Puerto Rico", "Quebec", "Rhode Island", "Saskatchewan", "South Carolina", "South Dakota", "Tennessee", "Texas", "U.S. Armed Forces - Americas", "U.S. Armed Forces - Europe", "U.S. Armed Forces - Pacific", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington","West Virginia", "Wisconsin", "Wyoming", "Yukon"],
                options: [],
                trackBy: "name",
                label: "name",
                multiple: false,
                checkboxes: true,
                closeOnSelect: true,
                showLabels: false,
                maxHeight: 250,
                placeholder: "Select State",
                tagPlaceholder: "",
            },
            class: "custom-cols-4",
            isSourceSpecific: true,
        },
        /**
         * @property
         * @name postalCode - postalCode filter data
         */
        curr_postalCode: {
            label: "Postal Code",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Postal Code",
                maxlength: 20,
            },
        },
        /**
         * @property
         * @name lexisCountry - Country filter data
         */
        curr_lexisCountry: {
            label: "Country",
            value: null,
            props: {
                options: [],
                groupLabel: "group",
                groupValues: "categories",
                groupSelect: true,
                trackBy: "name",
                label: "name",
                multiple: false,
                limit: 3,
                checkboxes: true,
                placeholder: "Select Country",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
            },
            class: "custom-cols-4",
            isSourceSpecific: false,
        },
        /**
         * Lexis Bridger Filters - Previous Address
         */
        /**
         * @property
         * @name address1 - address1 filter data
         */
        prev_address1: {
            label: "Address Line 1",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Address Line 1",
                maxlength: 100,
            },
        },
        /**
         * @property
         * @name address2 - address2 filter data
         */
        prev_address2: {
            label: "Address Line 2",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Address Line 2",
                maxlength: 100,
            },
        },
        /**
         * @property
         * @name city - city filter data
         */
        prev_city: {
            label: "City",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "City",
                maxlength: 50,
            },
        },
        /**
         * @property
         * @name state - state filter data
         */
        prev_state: {
            label: "State",
            value: null,
            props: {
                searchable: true,
                // options: [ "Alabama", "Alaska", "Alberta", "American Samoa", "Arizona", "Arkansas", "British Columbia", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Federated States of Micronesia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Manitoba", "Marshall Islands", "Maryland", "Massachusetts", "Michigan", "Military Identification", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Brunswick", "New Hampshire", "New Jersey", "New Mexico", "New York", "Newfoundland", "Newfoundland and Labrador", "North Carolina", "North Dakota", "Northern Mariana Islands", "Northwest Territories", "Oregon", "Palau", "Pennsylvania", "Prince Edward Island", "Puerto Rico", "Quebec", "Rhode Island", "Saskatchewan", "South Carolina", "South Dakota", "Tennessee", "Texas", "U.S. Armed Forces - Americas", "U.S. Armed Forces - Europe", "U.S. Armed Forces - Pacific", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington","West Virginia", "Wisconsin", "Wyoming", "Yukon"],
                options: [],
                trackBy: "name",
                label: "name",
                multiple: false,
                checkboxes: true,
                closeOnSelect: true,
                showLabels: false,
                maxHeight: 250,
                placeholder: "Select State",
                tagPlaceholder: "",
            },
            class: "custom-cols-4",
            isSourceSpecific: true,
        },
        /**
         * @property
         * @name postalCode - postalCode filter data
         */
        prev_postalCode: {
            label: "Postal Code",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Postal Code",
                maxlength: 20,
            },
        },
        /**
         * @property
         * @name lexisCountry - Country filter data
         */
        prev_lexisCountry: {
            label: "Country",
            value: null,
            props: {
                options: [],
                groupLabel: "group",
                groupValues: "categories",
                groupSelect: true,
                trackBy: "name",
                label: "name",
                multiple: false,
                limit: 3,
                checkboxes: true,
                placeholder: "Select Country",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
            },
            class: "custom-cols-4",
            isSourceSpecific: false,
        },
        /**
         * Lexis Bridger Filters - Mailing Address
         */
        /**
         * @property
         * @name address1 - address1 filter data
         */
        mailing_address1: {
            label: "Address Line 1",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Address Line 1",
                maxlength: 100,
            },
        },
        /**
         * @property
         * @name address2 - address2 filter data
         */
        mailing_address2: {
            label: "Address Line 2",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Address Line 2",
                maxlength: 100,
            },
        },
        /**
         * @property
         * @name city - city filter data
         */
        mailing_city: {
            label: "City",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "City",
                maxlength: 50,
            },
        },
        /**
         * @property
         * @name state - state filter data
         */
        mailing_state: {
            label: "State",
            value: null,
            props: {
                searchable: true,
                // options: [ "Alabama", "Alaska", "Alberta", "American Samoa", "Arizona", "Arkansas", "British Columbia", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Federated States of Micronesia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Manitoba", "Marshall Islands", "Maryland", "Massachusetts", "Michigan", "Military Identification", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Brunswick", "New Hampshire", "New Jersey", "New Mexico", "New York", "Newfoundland", "Newfoundland and Labrador", "North Carolina", "North Dakota", "Northern Mariana Islands", "Northwest Territories", "Oregon", "Palau", "Pennsylvania", "Prince Edward Island", "Puerto Rico", "Quebec", "Rhode Island", "Saskatchewan", "South Carolina", "South Dakota", "Tennessee", "Texas", "U.S. Armed Forces - Americas", "U.S. Armed Forces - Europe", "U.S. Armed Forces - Pacific", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington","West Virginia", "Wisconsin", "Wyoming", "Yukon"],
                options: [],
                trackBy: "name",
                label: "name",
                multiple: false,
                checkboxes: true,
                closeOnSelect: true,
                showLabels: false,
                maxHeight: 250,
                placeholder: "Select State",
                tagPlaceholder: "",
            },
            class: "custom-cols-4",
            isSourceSpecific: true,
        },
        /**
         * @property
         * @name postalCode - postalCode filter data
         */
        mailing_postalCode: {
            label: "Postal Code",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Postal Code",
                maxlength: 20,
            },
        },
        /**
         * @property
         * @name lexisCountry - Country filter data
         */
        mailing_lexisCountry: {
            label: "Country",
            value: null,
            props: {
                options: [],
                groupLabel: "group",
                groupValues: "categories",
                groupSelect: true,
                trackBy: "name",
                label: "name",
                multiple: false,
                limit: 3,
                checkboxes: true,
                placeholder: "Select Country",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
            },
            class: "custom-cols-4",
            isSourceSpecific: false,
        },
        /**
         * Lexis Bridger Filters - Phone Number
         */
        /**
         * @property
         * @name business - business filter data
         */
        business: {
            label: "Business",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Business",
            },
        },
        /**
         * @property
         * @name home - home filter data
         */
        home: {
            label: "Home",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Home",
            },
        },
        /**
         * @property
         * @name work - work filter data
         */
        work: {
            label: "Work",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Work",
            },
        },
        /**
         * @property
         * @name cell - cell filter data
         */
        cell: {
            label: "Cell",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Cell",
            },
        },
        /**
         * @property
         * @name fax - fax filter data
         */
        fax: {
            label: "Fax",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Fax",
            },
        },
        /**
         * @property
         * @name unknown - unknown filter data
         */
        unknown: {
            label: "Unknown",
            value: "",
            type: "string",
            class: "custom-cols-2",
            isSourceSpecific: true,
            props: {
                placeholder: "Unknown",
            },
        },
        /**
         * Lexis Bridger Filters - DUNS
         */
        /**
         * @property
         * @name dunsId - dunsId filter data
         */
        dunsId: {
            label: "ID Number",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "ID Number",
            },
        },
        /**
         * @property
         * @name issuingCountry - issuingCountry filter data
         */
        issuingCountry: {
            label: "Issuing Country",
            value: [],
            props: {
                options: [],
                groupLabel: "group",
                groupValues: "categories",
                groupSelect: true,
                trackBy: "name",
                label: "name",
                multiple: false,
                limit: 3,
                checkboxes: true,
                placeholder: "Issuing Country",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
            },
            class: "custom-cols-4",
            isSourceSpecific: false,
        },
        /**
         * @property
         * @name issueDate - issueDate filter data
         */
        issueDate: {
            label: "Issue Date",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Issue Date",
            },
        },
        /**
         * @property
         * @name expirationDate - expirationDate filter data
         */
        expirationDate: {
            label: "Expiration Date",
            value: "",
            type: "string",
            class: "custom-cols-4",
            isSourceSpecific: true,
            props: {
                placeholder: "Expiration Date",
            },
        },
        country: {
            label: "Country",
            value: [],
            props: {
                options: [],
                groupLabel: "group",
                groupValues: "categories",
                groupSelect: true,
                trackBy: "name",
                label: "name",
                multiple: true,
                limit: 3,
                checkboxes: true,
                placeholder: "Select Country",
                tagPlaceholder: "",
                closeOnSelect: false,
                showLabels: false,
            },
            class: "tw-w-full",
            isSourceSpecific: false,
        },
        /**
         * @property
         * @name fuzzinessLevel - fuzzinessLevel filter data
         */
        fuzzinessLevel: {
            label: "Fuzziness level",
            value: 0.7,
            props: {
                options: ["0.1", "0.2", "0.3", "0.4", "0.5", "0.6", "0.7", "0.8", "0.9", "1.0"],
                maxHeight: 250,
                placeholder: "Select Fuzziness Level",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
                allowEmpty: false,
                checkboxes: true,
            },
            class: "tw-w-1/3",
            isSourceSpecific: true,
        },
        // lexisfuzzinessLevel: {
        //     label: "Fuzziness level",
        //     value: "",
        //     props: {
        //         options: ["High", "Medium", "Low"],
        //         maxHeight: 250,
        //         placeholder: "Select Fuzziness Level",
        //         tagPlaceholder: "",
        //         closeOnSelect: true,
        //         showLabels: false,
        //         allowEmpty: false,
        //         checkboxes: true,
        //     },
        //     isSourceSpecific: true,
        // },
        monthOptions: [],
        dayOptions: [],
        yearOfBirthOptions: [],
        countriesOptions: [],
        icij_entity_type: [],
        isSavedLoading: {
            loading: false,
            loadingCardIndex: null,
        },
        isBulkMonntoringLoading: false,
        savedFilter: "all",
        request_id: null,
        lexis_request_id: null,
        isExpandedLoaded: false,
        offshore_entities: [],
        perPageOptions: [10, 20, 50, 100],
        order_by: {
            name: "Updated Date",
            value: "updated_at",
        },
        sortKeysMapping: {
            created_at: {
                complyAdvantage: "created_utc",
                worldCheck: "entered",
                DowJones: "created_at",
                offshoreLeaks: "",
            },
            updated_at: {
                complyAdvantage: "last_updated_utc",
                worldCheck: "updated",
                DowJones: "updated_at",
                offshoreLeaks: "",
            },
        },
        appliedFilterCount: 0,
        filters: {
            saved: "all",
            monitoring: "all",
        },
        filteredSources: {},
        prevAppliedFilters: {
            monitoring: "all",
            saved: "all",
            sourceType: [],
            searchQuery: "",
            filterEntityType: [],
            filterTypes: [],
            filterJurisdiction: [],
        },
        isBulkMonitorOn: false,
        latestVersionTotalResults: 0,
        timeline: [],
        offset: 0,
        isFilterApplied: false,
        case_id: "",
        isComparisonModeOn: false,
        searchInput: "",
    }),
    computed: {
        ...mapGetters(["getReadOnlyMode", "getCaseId", "getProduct", "getResearchData", "getAllToolsData", "getActiveCaseId", "getCaseNames", "getSocialPlatforms", "getPermissions", "checkPermissionsFetched", "getSelectedToolQuery", "getMonitoringDateFilter", "getAllMonitoringData", "getToolsFilterData", "nerFilters"]),

        bulkUploadAuth() {
            return this.getPermissions.includes("bulk_query__adverse_pep_sacntion");
        },

        searchQueryExists() {
            let flag = true;
            if (this.toolSearchQueries.findIndex((el) => el._id === this.getSelectedToolQuery?.query_id) < 0) flag = false;
            return flag;
        },

        inputInformation() {
            let info =
                '<ul style="list-style: none;"><strong>Using Chips for Multiple Values</strong>' +
                "<li>1. <strong>Enter:</strong> Type a value in the input field and press enter or  press comma to create a chip.</li>" +
                "<li>2. <strong>Add More:</strong> Repeat for additional values.</li>" +
                '<li>3. <strong>Remove:</strong> Click the "x" on a chip to delete a value.</li>' +
                "<li>4. <strong>Edit:</strong> Select a value in the chip or modify text to modify its value.</li>" +
                "<li>5. <strong>Bulk Enter:</strong> Copy text from Excel or line by line from a text file and paste it into the field will convert the line items to chips.</li></ul>";
            return info;
        },

        sortOptions() {
            if (this.order_by?.value == "updated_at") {
                return [
                    {
                        name: "Updated Date",
                        value: "updated_at",
                    },
                    {
                        name: "Created Date",
                        value: "created_at",
                    },
                ];
            }
            if (this.order_by.value == "created_at") {
                {
                    return [
                        {
                            name: "Created Date",
                            value: "created_at",
                        },
                        {
                            name: "Updated Date",
                            value: "updated_at",
                        },
                    ];
                }
            }
        },

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },
        socialProfiles() {
            return this.getSocialPlatforms.filter((n) => n.key === "linkedin" || n.key === "twitter" || n.key === "github" || n.key === "gitlab" || n.key === "instagram" || n.key === "youtube");
        },
        /**
         * @property
         * @name csvData
         * @return {Array} - return search results data to be downloaded in csv
         */
        csvData() {
            return this.convertToCSV(this.adverseResultsList);
        },

        /**
         * @property
         * @name csvFields
         * @return {Array} - return list of fields to be downloaded in csv
         */
        csvFields() {
            return ["name", "aka_names", "types", "entity_type", "dob", "locations"];
        },
        csvLabels() {
            return {name: "Name", aka_names: "Aka Names", types: "Types", entity_type: "Entity Type", dob: "DOB", locations: "Locations"};
        },

        uploadUrl() {
            return `osint-tools/search/bulk-upload`;
        },

        /**
         * @property
         * @name expandedCard
         * @return {Object} - return data for the selected card
         */
        expandedCard() {
            return this.adverseFilteredResults[this.selectedResultCard]?.api_all_data ?? {};
        },
        /**
         * @property
         * @name adverseResultsList
         * @return {Array} - return list of all search results after applying filters
         */
        adverseResultsList() {
            let data = cloneDeep(this.adverseFilteredResults);
            // if (this.$route.name === "monitoring" && this.filterNewResults) {
            //     data = data.filter((res) => res?.api_all_data?.hasChanges);
            //     console.log(data);
            // }

            return data;
        },
        /**
         * @property
         * @name getTotalPages
         * @return {Number} - return number of total pages of search results
         */
        getTotalPages() {
            return Math.ceil(this.adverseResultsList.length / this.items_count);
        },

        /**
         * @property
         * @name isTypeFilterDisabled
         * @return {Boolean} - check if type outupt filter should be enabled or not
         */

        isTypeFilterDisabled() {
            return !this.allSources["offshoreLeaks"]?.isChecked && !this.allSources["complyAdvantage"]?.isChecked && !this.allSources["worldCheck"]?.isChecked && !this.allSources["djWl"]?.isChecked && !this.allSources["djSoe"]?.isChecked && !this.allSources["djAme"]?.isChecked;
        },

        /**
         * @property
         * @name getSourcesList
         * @return {Array} - return list of sources (output filters)
         */
        getSourcesList() {
            let data = [];
            let sources = [];
            this.adverseResults?.forEach((src) => {
                let match = -1;
                if (src?.key && !sources.includes(src?.key) && src?.key !== "dowJones") {
                    // match = this.adverseResultsList.findIndex((result) => result?.key === src?.key)

                    sources.push(src?.key);
                    data.push({
                        name: src?.key,
                        // $isDisabled: match >=0 ? false: true,
                    });
                } else if (src?.key === "dowJones") {
                    // && !data.includes(src?.api_all_data?.sources
                    // APPEND DJ TO WL ? SOE ? AME sources
                    src?.api_all_data?.sources.forEach((srx) => {
                        let newSource = `DJ-${srx.toUpperCase()}`;
                        if (!sources.includes(newSource)) {
                            // match = this.adverseResultsList.findIndex((result) => result?.api_all_data?.sources?.includes(srx))

                            sources.push(newSource);
                            data.push({
                                name: newSource,
                                // $isDisabled: match >= 0 ? false: true,
                            });
                        }
                    });
                }
            });

            return [{group: "Source", categories: data}];
        },

        /**
         * @property
         * @name getEntityTypes
         * @return {Array} - return list of entity types (output filters)
         */
        getEntityTypes() {
            let results = [];
            this.adverseResults?.map((e) => {
                if (e.key === "lexisBridger") {
                    if (!results.some((res) => res.value === "individual"))
                        results.push({
                            value: "individual",
                            source: e?.key,
                        });
                    if (!results.some((res) => res.value === "business"))
                        results.push({
                            value: "business",
                            source: e?.key,
                        });
                } else if (e?.entity_type && !results.some((res) => res.value === e?.entity_type?.toLowerCase()))
                    results.push({
                        value: e?.entity_type?.toLowerCase(),
                        source: e?.key,
                    });
            });
            this.offshore_entities?.map((e) => {
                if (e?.entity_type && !results.some((res) => res.value === e?.entity_type?.toLowerCase()))
                    results.push({
                        value: e?.entity_type?.toLowerCase(),
                        source: "offshoreLeaks",
                    });
            });
            return [{group: "Entity Type", categories: results}];
        },

        /**
         * @property
         * @name getFilterTypes
         * @return {Array} - return list of types (output filters)
         */
        getFilterTypes() {
            let data = [];
            this.adverseResults?.forEach((adver) => {
                let advfilters = [];
                let adverseMediaCount = 0;
                let adversePepCount = 0;
                let pepCount = 0;
                let sanctionCount = 0;
                let adv = adver.types.split(",").forEach((adv) => {
                    if (!adv.includes("adverse") && !adv.includes("pep") && !adv.includes("PEP ") && !adv.includes("sanction") && !adv.includes("Sanctions")) {
                        advfilters.push(adv);
                    } else if (adv.includes("adverse")) {
                        adverseMediaCount++;
                    } else if (adv.includes("pep")) {
                        adversePepCount++;
                    } else if (adv.includes("PEP")) {
                        pepCount++;
                    } else if (adv.includes("sanction") || adv.includes("Sanctions")) {
                        sanctionCount++;
                    }
                });
                if (adverseMediaCount > 0) {
                    advfilters.push("adverse-media");
                }
                data.push(
                    ...advfilters.map((el) => ({
                        label: el.replace(/-/g, " ")?.replace(/None/g, "Others"),
                        value: el,
                    }))
                );
                if (adversePepCount > 0) {
                    data.push({
                        label: "PEP Listing",
                        value: "pep",
                    });
                }
                if (pepCount > 0) {
                    data.push({
                        label: "PEP/Warnings Details",
                        value: "PEP ",
                    });
                }
                if (sanctionCount > 0) {
                    data.push({
                        label: "Sanctions",
                        value: "sanction",
                    });
                }
            });
            return [{group: "Entity Type", categories: uniqBy(data, "label").filter((el) => el.label)}];
        },

        /**
         * @property
         * @name getFilterJurisdiction
         * @return {Array} - return list of jurisdiction (output filters)
         */

        getFilterJurisdiction() {
            let countries = new Set();
            this.adverseResults?.forEach((result) => {
                if (result.key === "worldCheck") {
                    countries.add(result.api_all_data.citizenship.toLowerCase());
                } else if (result.key === "dowJones") {
                    countries.add(...result.jurisdiction.split(",").map((el) => el.toLowerCase()));
                } else if (result.key === "offshoreLeaks" || result.key === "lexisBridger") {
                    countries.add(...result.jurisdiction?.split(",")?.map((el) => el.toLowerCase()));
                } else {
                    result?.api_all_data?.doc?.fields?.forEach((field) => {
                        if (field.name.toLowerCase() === "countries" || field.name.toLowerCase() === "country") {
                            countries.add(...field.value.split(",").map((el) => el.toLowerCase()));
                            return true;
                        } else return false;
                    });
                }
            });
            // || field.toLowerCase() === 'countries' ||field.toLowerCase() === 'country'
            let data = Array.from(countries);

            return [{group: "Jurisdiction", categories: data.sort((a, b) => a.localeCompare(b))?.filter((el) => el)}];
        },

        /**
         * @property
         * @name isMonitoringTab
         * @return {Boolean} - check if monitoring tab is selected
         */

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },

        /**
         * @property
         * @name checkMarkAllDone
         * @return {Boolean} - Monitoring - check if all results have been marked done or not
         */
        checkMarkAllDone() {
            let searchKeyword = this.searchQuery;
            let result = [];
            let adver_result = [];
            result = this.adverseFilteredResults;
            adver_result = this.adverseResultsList;
            result = result.slice(this.items_count * (this.page_number - 1), this.page_number * this.items_count);
            if (result && result.length) {
                return result.filter((res) => res?.name?.toLowerCase().includes(searchKeyword)).every((res) => res.api_all_data.changes_viewed);
            } else {
                return false;
            }
            this.$forceUpdate();
        },

        /**
         * @property
         * @name checkBulkToggleOn
         * @return {Boolean} - Monitoring - check if all results have toggle monitoring on or off
         */

        checkBulkToggleOn() {
            let searchedResultList = [];
            searchedResultList = this.adverseResultsList;
            if (searchedResultList && searchedResultList.length) {
                this.isBulkMonitorOn = searchedResultList.every((result) => result.api_all_data.monitoring_status);
                return searchedResultList.every((result) => result.api_all_data.monitoring_status);
            } else return false;
            // let searchKeyword = this.searchQuery;
            // let result = [];
            // let adver_result = [];
            // result = this.adverseFilteredResults;
            // adver_result = this.adverseResultsList;
            // result = result.slice(this.items_count * (this.page_number - 1), this.page_number * this.items_count);
            // if (result && result.length) {
            //     return adver_result.filter((res) =>
            //     res?.name?.toLowerCase().includes(searchKeyword)).every((res) =>
            //     res.api_all_data.monitoring_status);
            // }
            // else return false;
            // this.$forceUpdate();
        },

        /**
         * @property
         * @name isSearchBtnDisabled
         * @return {Boolean} - check if search button is disabled
         */
        isSearchBtnDisabled() {
            if (((this.entityType === "person" && (!this.fName?.value || !this.lName?.value)) || (this.entityType === "company" && !this.selectedItem?.value)) && !this.isAllRunQueries) return true;

            // if (this.isInvalid(this.fName, "name") || this.isInvalid(this.mName, "name") || this.isInvalid(this.lName, "name")) return true;

            for (const si in this.allSources["lexisBridger"].filterSections) {
                for (const fi in this.allSources["lexisBridger"].filterSections[si].filters) {
                    const filter = this.allSources["lexisBridger"].filterSections[si].filters[fi];
                    if (this.isInvalid(filter.value, filter.label?.toLowerCase())) return true;
                }
            }

            for (const filter in this.allSources) {
                if (!this.allSources[filter].disabled && this.allSources[filter].isChecked) return false;
            }
            return true;
        },

        allSourcesChecked() {
            let allSourcesChecked = true;
            for (let source in this.allSources) {
                if (!this.allSources[source].isChecked) {
                    allSourcesChecked = false;
                    break;
                }
            }
            return allSourcesChecked;
        },

        last_run_date() {
            return this.getSelectedToolQuery?.query?.last_runtime;
        },
        isEnabledApplyButton() {
            let {monitoring, saved} = this.filters;
            let appliedFilters = {
                monitoring,
                saved,
                sourceType: this.sourceType,
                searchQuery: this.searchQuery,
                filterEntityType: this.filterEntityType,
                filterTypes: this.filterTypes,
                filterJurisdiction: this.filterJurisdiction,
            };
            return isEqual(appliedFilters, this.prevAppliedFilters);
        },

        lexisSourceBody() {
            let body;
            if (this.entityType === "person") {
                body = {
                    entity_type: "Individual",
                    person_entity: {
                        first_name: this.fName?.value,
                        last_name: this.lName?.value,
                    },
                    sources: ["lexis"],
                };
                if (this.mName?.value) body.person_entity.middle_name = this.mName?.value;
            } else {
                body = {
                    entity_type: "Business",
                    company_entity: {
                        name: this.selectedItem?.value ?? "",
                    },
                    sources: ["lexis"],
                };
            }

            for (const si in this.allSources["lexisBridger"].filterSections) {
                const section = this.allSources["lexisBridger"].filterSections[si];
                if (["Current Address", "Previous Address", "Mailing Address"].includes(section.label)) {
                    let key;
                    if (section.label === "Current Address") key = "curr";
                    else if (section.label === "Previous Address") key = "prev";
                    else if (section.label === "Mailing Address") key = "mailing";
                    {
                        const street1 = section.filters.find((filter) => filter.key === `${key}_address1`).value;
                        const street2 = section.filters.find((filter) => filter.key === `${key}_address2`).value;
                        const city = section.filters.find((filter) => filter.key === `${key}_city`).value;
                        const stateprovincedistrict = section.filters.find((filter) => filter.key === `${key}_state`).value?.value;
                        const postalcode = section.filters.find((filter) => filter.key === `${key}_postalCode`).value;
                        const country = section.filters.find((filter) => filter.key === `${key}_lexisCountry`).value?.value;
                        if (street1 || street2 || city || stateprovincedistrict || postalcode || country) {
                            if (!body.addresses) body.addresses = [];
                            body = {
                                ...body,
                                addresses: [...body.addresses].concat([
                                    {
                                        type: section.label.split(" Address")?.[0],
                                        street1: street1,
                                        street2: street2,
                                        city: city,
                                        stateprovincedistrict: stateprovincedistrict,
                                        postalcode: postalcode,
                                        country: country,
                                    },
                                ]),
                            };
                        }
                    }
                } else if (section.label === "Phone Number") {
                    for (const fi in section.filters) {
                        const filter = section.filters[fi];
                        if ((filter.type === "string" && filter.value) || (filter.props && filter.props.options && filter.value?.value)) {
                            if (!body.phones) body.phones = [];
                            body = {
                                ...body,
                                phones: [...body.phones].concat([
                                    {
                                        type: filter.label,
                                        number: filter.value,
                                    },
                                ]),
                            };
                        }
                    }
                } else if (section.label === "Identification") {
                    for (const fi in section.filters) {
                        const filter = section.filters[fi];
                        if (filter.key === "nationalId" && filter.value) {
                            if (!body.ids) body.ids = [];
                            body = {
                                ...body,
                                ids: [...body.ids].concat([
                                    {
                                        type: "National",
                                        number: filter.value,
                                    },
                                ]),
                            };
                        } else if (filter.key === "citizenship" && filter.value?.value) {
                            if (!body.additional_info) body.additional_info = [];
                            body = {
                                ...body,
                                additional_info: [...body.additional_info].concat([
                                    {
                                        type: "Citizenship",
                                        value: filter.value?.value || "",
                                    },
                                ]),
                            };
                        } else if (filter.key === "lexisDateOfBirth" && filter.drops.some((drop) => drop.value)) {
                            if (!body.additional_info) body.additional_info = [];
                            body = {
                                ...body,
                                additional_info: [...body.additional_info].concat([
                                    {
                                        type: "DOB",
                                        date: {
                                            year: filter.drops?.[2]?.value?.toString(),
                                            month: filter.drops?.[1]?.value ? (filter.drops?.[1]?.props?.options?.findIndex((idx) => idx === filter.drops?.[1]?.value) + 1)?.toString().padStart(2, "0") : "",
                                            date: filter.drops?.[0]?.value?.toString().padStart(2, "0"),
                                        },
                                    },
                                ]),
                            };
                        } else {
                            if (filter.type === "string" && filter.value) {
                                if (!body.ids) body.ids = [];
                                body = {
                                    ...body,
                                    ids: [
                                        {
                                            type: filter.label,
                                            number: filter.value,
                                        },
                                    ],
                                };
                            } else if (filter.props && filter.props.options && filter.value?.value) {
                                if (!body.ids) body.ids = [];
                                body = {
                                    ...body,
                                    ids: [
                                        {
                                            type: filter.label,
                                            number: filter.value?.value || "",
                                        },
                                    ],
                                };
                            }
                        }
                    }
                } else if (section.label === "DUNS") {
                    for (const fi in section.filters) {
                        const filter = section.filters[fi];
                        if (filter.key === "dunsId" && filter.value) {
                            if (!body.ids) body.ids = [];
                            body = {
                                ...body,
                                ids: [...body.ids].concat([
                                    {
                                        type: "DUNS",
                                        number: filter.value,
                                    },
                                ]),
                            };
                        }
                    }
                }
            }
            return body;
        },
    },
    watch: {
        showResult() {
            this.$el.scroll(0, 0);
        },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },
    },
    created() {
        EventBus.$on("handleDone", (data) => {
            let result = cloneDeep(this.adverseResults);
            if (!data.oldVersion) {
                let index = result.findIndex((el) => el.curr_index === data.curr_index);
                if (index > -1) {
                    result[index] = data.card;
                    this.adverseResults = result;
                    // this.checkMarkAllDone()
                }
            } else {
                let oldVersionResults = cloneDeep(this.timeline);
                let index = oldVersionResults.findIndex((el) => el.curr_index === data.curr_index);
                if (index > -1) {
                    oldVersionResults[index] = data.card;
                    this.timeline = oldVersionResults;
                    // this.checkMarkAllDone()
                }
            }
            this.filterData();
            this.$emit("refreshQueries");
            this.$forceUpdate();
        });
        EventBus.$on("handleResultSave", (data) => {
            // API Call to save card
            this.handleSavedCard(data);
        });

        EventBus.$on("showMoreData", (data) => {
            this.expandResult(data);
        });
    },
    async mounted() {
        /**
         * map filters list and default selected data
         */
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;

        await this.getToolData();
        await this.fetchAllJuridictions();
        await this.fetchAllStates();
        this.fillYears();
        await this.initalizeData();
        const dataRes = this.getToolsData(this.tabName, "lexis-bridger-tool");
        if (dataRes) this.redirectToQuery(dataRes);

        // this.setToolsFilterData(this.filters);
        // const { monitoring, saved, sources, entity_type, types, jurisdiction } = this.filters;
        // if (monitoring !== "all" || saved !== "all" || sources.length > 0 || entity_type.length > 0 || types.length > 0 || jurisdiction.length > 0) {
        // await this.getToolsStatus();
        // }
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "lexis-bridger-tool");
        EventBus.$off("handleDone");
        EventBus.$off("handleResultSave");
        EventBus.$off("showMoreData");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_MONITORING_DATE_FILTERS", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseNames", "setToolsFilterData", "getToolsStatus"]),
        getMappingValue,
        getMonitoring,

        async getToolData() {
            const companies = await this.getDropdownData("company_name");
            this.nameSearches = [
                {
                    group: "Entities",
                    categories: companies,
                },
            ];

            let persons = await this.getDropdownData("name");
            this.personSearches = [
                {
                    group: "Entities",
                    categories: persons,
                },
            ];

            const first_names = [];
            const middle_names = [];
            const last_names = [];
            persons = persons.forEach((name) => {
                let list = name.value.split(" ");
                const first = list?.[0].trim();
                const last = list?.length > 1 ? list?.[list.length - 1].trim() : "";

                list.splice(0, 1);
                list.splice(list.length - 1, 1);
                const middle = list.join(" ").trim();

                if (first)
                    first_names.push({
                        ...name,
                        value: first,
                    });

                if (last)
                    last_names.push({
                        ...name,
                        value: last,
                    });

                if (middle)
                    middle_names.push({
                        ...name,
                        value: middle,
                    });
            });

            this.fNameSearches = [
                {
                    group: "Entities",
                    categories: uniqBy(first_names, "value"),
                },
            ];
            this.mNameSearches = [
                {
                    group: "Entities",
                    categories: uniqBy(middle_names, "value"),
                },
            ];
            this.lNameSearches = [
                {
                    group: "Entities",
                    categories: uniqBy(last_names, "value"),
                },
            ];
        },
        // fetchSource(key) {},
        onToggle() {
            this.open = !this.open;
        },
        handleOutside() {
            this.open = false;
        },

        async handleSavedCard(cardData) {
            this.isSavedLoading = {
                loading: true,
                loadingCardIndex: cardData.api_all_data.doc_id,
            };
            let sources = [];
            if (cardData.key === "dowJones") {
                cardData.api_all_data?.sources?.map((src) => sources.push(src.toLowerCase()));
            } else {
                if (cardData.key === "lexisBridger") sources.push("lexis");
                else sources.push(cardData.key.toLowerCase());
            }
            let payload = {
                sources: sources,
                doc_id: cardData.api_all_data?.doc_id,
                saved: !cardData.api_all_data.saved,
                card_data: {
                    created_at: cardData?.created_at ?? null,
                    updated_at: cardData?.updated_at ?? null,
                    case_id: this.case_id,
                    query_id: this.request_id,
                    tool: "tool-adverse-meta",
                    tool_name: this.$route.params.toolName,
                },
            };
            let url = `/osint-tools/card/mark-as-saved`;
            try {
                const {data} = await mainAxios.post(url, payload);
                if (data.success === true) {
                    let result = cloneDeep(this.adverseResults);
                    let elementIndex = result.findIndex((obj) => obj.api_all_data.doc_id === cardData.api_all_data.doc_id);
                    let resultSavedElement = result[elementIndex];
                    let expandedResult = this.expandedResultCard;
                    resultSavedElement.api_all_data.saved = !resultSavedElement.api_all_data.saved;
                    if (expandedResult && expandedResult.api_all_data.doc_id === cardData.api_all_data.doc_id) {
                        expandedResult.api_all_data.saved = !expandedResult.api_all_data.saved;
                        this.expandedResultCard = expandedResult;
                    }
                    result.splice(elementIndex, 1, resultSavedElement);
                    this.adverseResults = result;
                    this.isSavedLoading = {
                        loading: false,
                        loadingCardIndex: null,
                    };
                    this.$toast.success("Updated!");
                    this.filterData();
                    this.$emit("refreshQueries");
                    this.$forceUpdate();
                }
            } catch {
                this.$toast.warning("Please try again after sometime!");
                this.isSavedLoading = {
                    loading: false,
                    loadingCardIndex: null,
                };
            }
        },
        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        handleChecked(data) {
            let allChecked = false;
            const allList = [...this.nameSearches[0]?.categories, ...this.personSearches[0]?.categories];
            this.$emit("updateToolData", "lexis-bridger", allList, data);
            allChecked = allList?.length ? (allList.findIndex((n) => !n.visited) > -1 ? false : true) : false;
            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        resetType() {
            if (this.sourceType?.name === "offshoreLeaks") this.filterTypes = "";
        },

        /**
         * @function
         * @name expandResult
         * @params {result, index, i} - result - selected card data
         *                            - index - selected card index
         */
        async expandResult(result) {
            const index = result.index;
            const isOldVersion = result.isOldVersion;
            this.isExpandedLoaded = true;
            if (result.key === "dowJones") {
                let id = result?.api_all_data["@id"];
                let entity_type = result?.api_all_data?.entity_type;
                let sources = result.api_all_data.sources;
                let associates = [];
                let query_id;
                if (this.isAllRunQueries) {
                    query_id = result?.api_all_data?.query_id;
                } else {
                    query_id = this.request_id;
                }
                if (id && entity_type) {
                    try {
                        for (const index in sources) {
                            const source = sources[index];
                            const src = source.toUpperCase();
                            const {data} = await axios.get(`/adverse-media/search/dj-associates/${id}?source=${src}&case_id=${this.case_id}&query_id=${query_id}&doc_id=${result.api_all_data.doc_id}`);
                            result.api_all_data[source] = {...result.api_all_data[source], ...data?.data[source]};
                        }
                        let src;
                        if (sources.includes("ame")) src = sources.splice(source.indexOf("ame"), 1);
                        else src = sources;
                        let payload = {
                            sources: src,
                            dj_id: result?.api_all_data["@id"],
                        };
                        const lastCheckedAt = await axios.post("/adverse-media/dj-last-checked-at", payload);
                        result.api_all_data = {...result.api_all_data, ...lastCheckedAt?.data?.data};
                    } catch (error) {}
                }
            } else if (result.key === "worldCheck") {
                let id = parseInt(result?.api_all_data["uid"], 10);
                let query_id;
                if (this.isAllRunQueries) {
                    query_id = result?.api_all_data?.query_id;
                } else {
                    query_id = this.request_id;
                }
                const postData = {
                    uid: id,
                    case_id: this.case_id,
                    query_id: query_id,
                    doc_id: result.api_all_data.doc_id,
                };
                if (id) {
                    try {
                        const {data} = await axios.post(`/adverse-media/worldcheck/detail`, postData);
                        let companiesReported = [];
                        let reportedLinked = [];
                        try {
                            Object.values(data?.data?.companies).forEach((company, index) => {
                                companiesReported.push({
                                    id: Object.keys(data.data.companies)[index],
                                    name: company?.name,
                                    country: company?.country,
                                });
                            });

                            Object.values(data?.data?.linked_to).forEach((company, index) => {
                                reportedLinked.push({
                                    id: Object.keys(data.data.linked_to)[index],
                                    name: company?.name,
                                    country: company?.country,
                                    dob: company?.dob,
                                });
                            });

                            result.api_all_data = {
                                ...result.api_all_data,
                                ...{
                                    companiesReported,
                                    reportedLinked,
                                },
                            };
                        } catch (error) {
                            console.log(error);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            } else if (result.key === "offshoreLeaks") {
                let id = result?.api_all_data["node_id"];
                let query_id;
                if (this.isAllRunQueries) {
                    query_id = result?.api_all_data?.query_id;
                } else {
                    query_id = this.request_id;
                }
                let payload = {
                    entity_id: id,
                    offset: 0,
                    limit: 100,
                    case_id: this.case_id,
                    query_id: query_id,
                    doc_id: result.api_all_data.doc_id,
                };
                if (id) {
                    try {
                        const {data} = await axios.post(`/adverse-media/icij-links`, payload);
                        data.data = data.data.map((item, index) => ({...item, index: index}));
                        const nodeTypes = uniqBy(data.data, "doc.data_type");
                        let nodes = [];
                        nodes = groupBy(data.data, "doc.data_type");
                        // nodes = mapValues(groupBy(data.data, "doc.data_type"), (nodelist) => nodelist.map((node) => omit(node, "doc.data_type")));
                        result.api_all_data = {
                            ...result.api_all_data,
                            ...{nodes},
                            ...{nodeTypes},
                        };
                    } catch (error) {}
                }
            }
            let detailsExpanded = true;
            if (result.detailsExpanded === false) detailsExpanded = false;

            if (detailsExpanded) {
                this.selectedResultCard = index;
                if (isOldVersion) {
                    this.timeline[index] = result;
                    this.isOldVersionSelected = true;
                } else {
                    this.adverseResults[index] = result;
                    this.isOldVersionSelected = false;
                }
                this.expandedResultCard = result;
                this.isResultExpanded = true;
                this.isComparisonModeOn = false;
                this.$emit("searchResult", {data: this.adverseResults, source: "lexis-bridger", searchType: "name"});
                if (result.expanded == undefined) result.expanded = true;
                else {
                    result.expanded = !result.expanded;
                }
                this.$forceUpdate();
                const resultContainer = document.getElementById(`result-expanded`);
                if (resultContainer) {
                    resultContainer.scrollTo({
                        top: 1,
                        behavior: "smooth",
                    });
                    // document.getElementById(`result-${index}`).scrollIntoViewIfNeeded(true);
                }
                this.isExpandedLoaded = false;
            } else {
                this.isComparisonModeOn = true;
                this.updateComparisonReferences(result);
            }
        },

        /**
         * @function
         * @name handleChangeCheck
         * @params {e} - pointer event for Complyadvantage checks input checkbox (adverse / peps/ sanction)
         */
        handleChangeCheck(e) {
            if (this.checks.includes(e.target.value)) {
                const filterCheck = this.checks.filter((el) => el !== e.target.value);
                this.checks = filterCheck;
            } else {
                this.checks.push(e.target.value);
            }
        },

        /**
         * @function
         * @name handleSourceChange
         * @params {e} - pointer event for source input checkbox (Complyadvantage / worldcheck / wl / soe / ame / offshoreleaks)
         */
        handleSourceChange(e) {
            if (this.sources.includes(e.target.value)) {
                const filterSource = this.sources.filter((el) => el !== e.target.value);
                this.sources = filterSource;
            } else {
                this.sources.push(e.target.value);
            }

            for (let source in this.allSources) {
                if (this.allSources[source].value === e.target.value) {
                    this.allSources[source].isChecked = !this.allSources[source].isChecked;
                    break;
                }
            }
        },

        /**
         * @function
         * @name handleSourceExpand
         * @params {source, value} - source & value (Complyadvantage / worldcheck / wl / soe / ame / offshoreleaks)
         */
        handleSourceExpand(source, value, toggle) {
            if (toggle) this.allSources[source].isChecked = !this.allSources[source].isChecked;
            if (this.sources.includes(value)) {
                const filterSource = this.sources.filter((el) => el !== value);
                this.sources = filterSource;
            } else {
                this.sources.push(value);
            }
        },

        /**
         * @function
         * @name updateSource
         * @params {e} - pointer event for source input checkbox (Complyadvantage / worldcheck / wl / soe / ame / offshoreleaks)
         */
        updateSource(e, source) {
            if (e.target.checked && this.allSources[source].filterSections && this.allSources[source].filterSections.length) {
                this.allSources[source].isModalVisible = true;
            } else {
                this.handleSourceChange(e);
            }
            // this.manualSearch();
        },

        /**
         * @function
         * @name applyFilter - apply all filters
         */
        applyFilters(source, value) {
            if (!this.sources.includes(value)) this.sources.push(value);
        },

        /**
         * @function
         * @name closeFilter - close filters popup
         */
        closeFilter(source, value) {
            if (this.allSources[source].isChecked && !this.sources.includes(value)) this.allSources[source].isChecked = false;
        },

        /**
         * @function
         * @name filterData - filter Data as per the filters applied
         */
        async filterData() {
            let data = cloneDeep(this.adverseResults);
            let searchKeyword = this.searchQuery.toLowerCase() ?? "";

            if (this.sourceType?.length) {
                data = data.filter((adv) => {
                    if (adv?.key !== "dowJones") {
                        return this.sourceType.some((source) => adv?.key === source.name);
                    } else {
                        return this.sourceType.some((source) => adv?.api_all_data?.sources.includes(source?.name.replace("DJ-", "").toLowerCase()));
                    }
                });
            }

            if (this.filterEntityType?.length) {
                data = data.filter((adv) => {
                    if (adv?.key === "lexisBridger") {
                        if (adv?.entity_type.toLowerCase() === "individual") return this.filterEntityType?.some((type) => type.value === "individual");
                        else return this.filterEntityType?.some((type) => type.value === "business");
                    } else this.filterEntityType.some((type) => adv?.entity_type?.toLowerCase() === type.value);
                });

                if (this.filterEntityType.some((type) => type.source === "offshoreLeaks")) {
                    this.icij_entity_type = this.filterEntityType?.filter((el) => el.source === "offshoreLeaks")?.map((el) => el?.value);

                    const headers = {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                        "x-refresh": false,
                    };
                    let sourcesCallBody = {
                        name: this.searchKeyword,
                        search_mode: this.searchMode.value.value,
                        icij_entity_type: this.icij_entity_type,
                        sources: ["offshoreleaks"],
                        age: this.isRangeEnalbed ? this.age.map((ag) => parseInt(ag)) : [],
                        limit: 100,
                        page: 1,
                    };

                    const {data: sourcesCall} = await axios.post("/adverse-media/search/sources", sourcesCallBody, {headers});
                    this.request_id = sourcesCall.request_id;
                    this.redirectTo();
                }
            }

            if (this.filterTypes?.length) {
                data = data.filter(
                    (adv) => {
                        return this.filterTypes.some((type) => adv?.types.includes(type.value) || adv?.types === type.value.toLowerCase() || adv?.types.includes(type.value.toLowerCase()));
                    }
                    // adv?.types
                    //     .split(",")
                    //     .map((el) => el.toLowerCase())
                    //     .includes(this.filterTypes.label.toLowerCase())
                );
            }

            if (this.filterJurisdiction?.length) {
                data = data.filter((adv) => {
                    if (adv.key === "worldCheck") {
                        return this.filterJurisdiction?.some((jurisdiction) => adv.api_all_data?.citizenship.toLowerCase() === jurisdiction.toLowerCase() || adv.api_all_data?.citizenship.includes(jurisdiction.toLowerCase()));
                    } else if (adv.key === "dowJones" || adv.key === "offshoreLeaks" || adv.key === "lexisBridger") {
                        return this.filterJurisdiction?.some((jurisdiction) => adv?.jurisdiction.toLowerCase() === jurisdiction.toLowerCase() || adv?.jurisdiction.toLowerCase().includes(jurisdiction) || adv?.jurisdiction.toLowerCase().includes(jurisdiction.toLowerCase()));
                    } else {
                        return this.filterJurisdiction?.some((jurisdiction) => adv?.api_all_data?.doc?.fields?.some((field) => (field.value.toLowerCase() === jurisdiction && (field.name.toLowerCase() === "country" || field.name.toLowerCase() === "countries")) || (field.value.toLowerCase().includes(jurisdiction) && (field.name.toLowerCase() === "country" || field.name.toLowerCase() === "countries"))));
                    }
                });
            }
            this.adverseFilteredResults = data.filter((ele) => ele?.name?.toLowerCase().includes(searchKeyword));
            let allFilters = ["filterEntityType", "sourceType", "filterTypes", "filterJurisdiction", "filters.saved", "filters.monitoring"];

            let that = this;
            this.allFilters = [];
            for (let filter in allFilters) {
                let filterValues = get(that, allFilters[filter]);
                if (allFilters[filter].includes(".")) {
                    filterValues = filterValues === "all" ? [] : [filterValues];
                }
                for (const value in filterValues) {
                    this.allFilters.push({
                        name: allFilters[filter],
                        value: filter === "sourceType" ? filterValues[value].name : filter === "filterTypes" || filter === "filterEntityType" ? filterValues[value].value : filterValues[value],
                    });
                }
            }
            this.appliedFilterCount = 0;
            if (this.isFilterApplied) {
                if (this.filters.saved !== "all") this.appliedFilterCount += 1;
                if (this.filters.monitoring !== "all") this.appliedFilterCount += 1;
                if (this.searchQuery) this.appliedFilterCount += 1;
                if (this.sourceType?.length) this.appliedFilterCount += 1;
                if (this.filterEntityType?.length) this.appliedFilterCount += 1;
                if (this.filterTypes?.length) this.appliedFilterCount += 1;
                if (this.filterJurisdiction?.length) this.appliedFilterCount += 1;
            }

            // this.page_number = 1;
            this.$forceUpdate();
        },

        async handleBulkMonitoring() {
            // const monitoringStatus = event.target.checked;
            if (this.getSelectedToolQuery.query_string.includes("complyadvantage") || this.getSelectedToolQuery.query_string.includes("worldcheck") || this.getSelectedToolQuery.query_string.includes("wl") || this.getSelectedToolQuery.query_string.includes("soe") || this.getSelectedToolQuery.query_string.includes("ame")) {
                this.bulkMonitor = true;
                this.isBulkMonntoringLoading = true;
                this.isBulkMonitorOn = !this.isBulkMonitorOn;
                let requestBody = {
                    product_name: this.getProduct.name,
                    product_id: this.getProduct.key,
                    case_id: this.$route.params.id,
                    query: {
                        query_id: this.getSelectedToolQuery.query_id,
                        query_string: this.getSelectedToolQuery.query_string.replace("lexis,", ""),
                        search_string: this.getSelectedToolQuery.search_string.replace("lexis,", ""),
                    },
                    frequency: this.$props.frequency,
                    status: this.isBulkMonitorOn ? "enabled" : "disabled",
                };
                try {
                    const response = await mainAxios.post("/data-monitoring/bulk-enable", requestBody);
                    if (response.status) {
                        this.bulkMonitor = false;
                        this.$toast.success("Bulk monitoring updated successfully");
                        // let start_count = this.items_count * (this.page_number - 1);
                        // let end_count = this.page_number * this.items_count;
                        let details = this.adverseResults;
                        details?.map((el) => {
                            el.api_all_data.monitoring_status = this.isBulkMonitorOn;
                        });
                        this.adverseResults = cloneDeep(details);
                        this.filterData();
                        this.$forceUpdate();
                    }
                } catch (error) {
                    this.$toast.warning(error.message);
                    console.log(error.message);
                }
                this.isBulkMonntoringLoading = false;
            } else this.$toast.success("Bulk monitoring cannot be enable for lexis");
        },

        /**
         * @function
         * @name redirectTo - function to update input filters data to the route
         */
        redirectTo() {
            let query = {};
            if (this.lexis_request_id)
                query = {
                    query_id: this.lexis_request_id,
                };
            this.$router.push({path: this.$route.path, query: query}).catch(() => {});
        },

        async resetQueryFilter() {
            const filters = {
                monitoring: "all",
                saved: "all",
            };
            this.setToolsFilterData(filters);
            await this.getToolsStatus();
        },

        manualSearch(refreshFlag) {
            this.triggerRoute = false;
            this.search(refreshFlag);
        },

        /**
         * When tab is monitoring
         * Fetch monitoring results
         * @param {array} sources: []
         */
        async monitoringSearch(sources) {
            this.showResult = true;
            this.startLoader("nameSearch");
            if (this.limitPag == 1) {
                this.offset = 0;
                this.adverseResults = [];
            }
            this.searchedClicked = true;
            //To navigate to a different URL, according to the selected query
            // this.redirectTo();
            if (this.limitPag > 1) {
                this.offset = Math.ceil((this.limitPag - 1) * 100);
            }
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery, this.offset);
            this.latestVersionTotalResults = values.totalResults;
            if (values?.values?.length > 0) {
                let world_check = this.getMappingValue(
                    values?.values?.filter((val) => val.doc_source === "worldcheck"),
                    "worldCheck",
                    ADVERSE_TOOL_MAPPING
                );
                let comply_advantage = this.getMappingValue(
                    values?.values?.filter((val) => val.doc_source === "complyadvantage"),
                    "complyAdvantage",
                    ADVERSE_TOOL_MAPPING
                );
                let dow_jones = this.getMappingValue(
                    values?.values?.filter((val) => val.doc_source === "dowjones"),
                    "dowJones",
                    ADVERSE_TOOL_MAPPING
                );
                let offshore_leaks = this.getMappingValue(
                    values?.values?.filter((val) => val.doc_source === "offshoreleaks"),
                    "offshoreLeaks",
                    ADVERSE_TOOL_MAPPING
                );
                this.adverseResults = [...this.adverseResults, ...world_check, ...comply_advantage, ...dow_jones, ...offshore_leaks];
                this.adverseResults = this.adverseResults.map((ele, index) => ({...ele, curr_index: index}));
            }
            this.resetLoader("nameSearch");
            this.$emit("getInitialQueryCount");
        },

        /**
         * @function
         * @name search - function calls to perform search on the input filters
         *              - calls sources API to create a search query
         *              - calls search APIs on the selected sources
         */
        async search(refreshFlag) {
            this.monitoringStatus = false;
            this.closeExpandedResult();
            this.adverseFilteredResults = [];
            this.isOldVersionSelected = false;
            this.timeline = [];
            this.$emit("updateMonitoringCascadingTimeline", {
                timeline: this.timeline,
                meta_data: null,
            });

            // this.isAllRunQueries = false;
            this.allQueriesParams = null;
            // for (let i = start; i >= 0; i--) {
            this.isResultExpanded = false;
            if (this.limitpag == 1) {
                this.adverseResults = [];
                this.offsetPag = 0;
                this.page_number = 1;
                this.adverseFilteredResults = [];
            }

            if (this.entityType === "company") this.setCompanyName();
            else this.setPersonName();

            this.page_number = 1;
            this.searchItem = this.searchKeyword;
            // const selCountries = this.country?.value.length > 0 ? this.country.value.map((el) => el.value).sort((a, b) => a.localeCompare(b)) : [];
            const selCountries = [];
            let year_of_birth = this.yearOfBirth.value;
            if (!this.isSourceAuth("comply_advantage")) {
                this.allSources["complyAdvantage"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "complyadvantage");
            }
            if (!this.isSourceAuth("lexis_bridger")) {
                this.allSources["lexisBridger"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "lexis");
            }
            if (!this.isSourceAuth("world_check")) {
                this.allSources["worldCheck"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "worldcheck");
            }
            if (!this.isSourceAuth("dow_jones")) {
                this.allSources["djWl"].isChecked = false;
                this.allSources["djSoe"].isChecked = false;
                if (!this.allSources["djAme"].disabled) this.allSources["djAme"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "soe" && e !== "ame" && e !== "wl");
            }
            if (this.$route.name === "monitoring") {
                await this.monitoringSearch(this.sources, "company");
                this.filterData();
                return;
            }

            if (this.searchKeyword || this.isAllRunQueries) {
                if (!this.sources.length && !this.isAllRunQueries) {
                    this.$toast.error("Please Select a Source");
                    return;
                }

                this.sources = this.sources.sort((a, b) => a.localeCompare(b));
                this.searchedClicked = true;
                this.startLoader("nameSearch");
                this.isLoading = true;
                this.$emit("searched", {data: {name: this.searchKeyword}, source: "lexis-bridger"});
                const headers = {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                    "x-refresh": refreshFlag || false,
                };
                let sourcesCallBody = {
                    name: this.searchKeyword,
                    sources: this.sources.map((ele) => ele.toLowerCase()).sort((a, b) => a.localeCompare(b)),
                    age: this.isRangeEnalbed ? this.age.map((ag) => parseInt(ag)) : [],
                    entity_type: this.entityType,
                };
                if (this.allSources["complyAdvantage"].isChecked) {
                    if (!this.checks.length && !this.isAllRunQueries) {
                        this.resetLoader("nameSearch");
                        this.isLoading = false;
                        this.$toast.error("Please provide at least one valid check for ComplyAdvantage");
                        return;
                    }
                    sourcesCallBody.year_of_birth = year_of_birth ? parseInt(year_of_birth) : "";
                    sourcesCallBody.fuzziness = parseFloat(this.fuzzinessLevel.value ? this.fuzzinessLevel.value : "0.5");
                    sourcesCallBody.country = selCountries;
                    sourcesCallBody.checks = this.checks.length > 0 ? this.checks.sort((a, b) => a.localeCompare(b)) : [];
                }
                if (this.allSources["worldCheck"].isChecked) {
                    sourcesCallBody.search_mode = this.searchMode.value.value;
                    sourcesCallBody.limit = 100;
                    sourcesCallBody.page = 1;
                }
                if (this.allSources["djWl"]?.isChecked || this.allSources["djSoe"]?.isChecked || this.allSources["djAme"]?.isChecked) {
                    sourcesCallBody.country = selCountries;
                    sourcesCallBody.limit = 100;
                    sourcesCallBody.page = 1;
                }

                // sourcesCallBody.age=this.age
                // const {data: sourcesCall} = await axios.post("/adverse-media/search/sources", sourcesCallBody, {headers});
                // this.request_id = sourcesCall.request_id;
                let lexisBridger, complyAdvantage, worldCheck, DowJones;
                if (this.allSources["lexisBridger"].isChecked) {
                    const {data: lexisSourcesCall} = await axios.post("/lexis_bridger/search_sources", this.lexisSourceBody, {headers});
                    this.lexis_request_id = lexisSourcesCall.request_id;
                    this.redirectTo();
                    this.showResult = true;
                    this.showSearch = false;

                    const reqBody = cloneDeep(this.lexisSourceBody);
                    reqBody.additional_details = {};
                    reqBody.additional_details.query_id = this.lexis_request_id;

                    if (this.nerFilters) reqBody.output_filters = this.nerFilters;
                    if (this.filters.monitoring !== "all" && this.isFilterApplied) {
                        reqBody.additional_details.monitoring_status = this.filters.monitoring === "monitoring";
                    }
                    if (this.filters.saved !== "all" && this.isFilterApplied) {
                        reqBody.additional_details.saved = this.filters.saved === "saved";
                    }
                    lexisBridger = axios.post("/lexis_bridger/search_entity?cache_bypass=false", reqBody, {
                        headers,
                    });
                }
                if (this.allSources["complyAdvantage"].isChecked) {
                    const reqBody = {
                        name: this.searchKeyword,
                        entity_type: this.entityType,
                        year_of_birth: year_of_birth,
                        fuzziness: parseFloat(this.fuzzinessLevel.value ? this.fuzzinessLevel.value : "0.5"),
                        country: selCountries,
                        checks: this.checks,
                        age: this.isRangeEnalbed ? this.age : [],
                        // sources: this.sources,
                        request_id: sourcesCall.request_id,
                        sort_by: this.sortKeysMapping[this.order_by?.value]["complyAdvantage"] || "",
                        sort_order: "desc",
                    };
                    if (this.nerFilters) reqBody.output_filters = this.nerFilters;
                    if (this.filters.saved !== "all") {
                        reqBody.saved = this.filters.saved === "saved";
                    }
                    if (this.filters.monitoring !== "all" && this.isFilterApplied) {
                        reqBody.monitoring_status = this.filters.monitoring === "monitoring";
                    }
                    complyAdvantage = axios.post("/adverse-media/search", reqBody, {
                        headers,
                    });
                }
                if (this.allSources["worldCheck"].isChecked) {
                    const body = {
                        name: this.searchKeyword,
                        limit: 100,
                        page: 1,
                        entity_type: this.entityType,
                        request_id: sourcesCall.request_id,
                        age: this.isRangeEnalbed ? this.age : [],
                        search_mode: this.searchMode.value.value,
                        sort_by: this.sortKeysMapping[this.order_by?.value]["worldCheck"] || "",
                        sort_order: "desc",
                    };
                    if (this.nerFilters) body.output_filters = this.nerFilters;
                    if (this.filters.saved !== "all" && this.isFilterApplied) {
                        body.saved = this.filters.saved === "saved";
                    }
                    if (this.filters.monitoring !== "all" && this.isFilterApplied) {
                        body.monitoring_status = this.filters.monitoring === "monitoring";
                    }
                    worldCheck = axios.post("/adverse-media/worldcheck", body, {
                        headers,
                    });
                }
                /// wl - WatchList
                /// soe - State Owned Entity
                /// AME - Adverse Media Enitites
                if (this.allSources["djWl"]?.isChecked || this.allSources["djSoe"]?.isChecked || this.allSources["djAme"]?.isChecked) {
                    const body = {
                        name: this.searchKeyword,
                        entity_type: this.entityType,
                        request_id: sourcesCall.request_id,
                        age: this.isRangeEnalbed ? this.age : [],
                        country: this.country.value.length ? this.country.value.map((el) => el.name).sort((a, b) => a.localeCompare(b)) : [],
                        limit: 100,
                        page: 1,
                        sources: this.sources.filter((src) => src != "complyadvantage" && src != "worldcheck" && src != "dowjones" && src != "offshoreleaks" && src != "lexis"),
                        sort_by: this.sortKeysMapping[this.order_by?.value]["DowJones"] || "",
                        sort_order: "desc",
                    };
                    if (this.nerFilters) body.output_filters = this.nerFilters;
                    if (this.filters.saved !== "all" && this.isFilterApplied) {
                        body.saved = this.filters.saved === "saved";
                    }
                    if (this.filters.monitoring !== "all" && this.isFilterApplied) {
                        body.monitoring_status = this.filters.monitoring === "monitoring";
                    }
                    DowJones = axios.post("/adverse-media/search/dj-search", body, {
                        headers,
                    });
                }
                this.$emit("searched", {data: {name: this.searchKeyword}, source: "lexis-bridger"});
                await this.setSearchedUser(this.searchKeyword);
                if (complyAdvantage || worldCheck || DowJones || lexisBridger) {
                    this.adverseResults = [];

                    Promise.allSettled([lexisBridger, complyAdvantage, worldCheck, DowJones])
                        .then(async (results) => {
                            let values = results.map((result) => result.value);
                            /// lexisBridger ///
                            if (values[0]?.data?.data?.length > 0) {
                                // const result = values[1].data?.data.map((e) => ({ ...e, source: "offshoreLeaks" }));
                                let sortedResults = values[0]?.data?.data.sort((a, b) => {
                                    return b.data.BestNameScore - a.data.BestNameScore;
                                });

                                const mappingValue = this.getMappingValue(sortedResults, "lexisBridger", ADVERSE_TOOL_MAPPING);
                                this.adverseResults = [...this.adverseResults, ...mappingValue];
                            }
                            ///// COMPLY ADVANTAGE //////
                            if (values[1]?.data?.data?.length > 0) {
                                // In the scope of PoC of the new Graph
                                const payload = this.makeDataForGraph(values[1]?.data?.data, "complyadvantage");
                                await saveToolGraphData(payload);

                                const mappingValue = this.getMappingValue(values[1]?.data?.data, "complyAdvantage", ADVERSE_TOOL_MAPPING);
                                this.adverseResults = [...this.adverseResults, ...mappingValue];
                            }
                            ///// WORLD CHECK //////
                            if (values[2]?.data?.data?.length > 0) {
                                const result = this.getMappingValue(values[2]?.data?.data, "worldCheck", ADVERSE_TOOL_MAPPING);
                                this.adverseResults = [...this.adverseResults, ...result];
                            }
                            // DowJones
                            if (values[3]?.data?.data?.length > 0) {
                                const result = this.getMappingValue(values[3].data?.data, "dowJones", ADVERSE_TOOL_MAPPING);
                                this.adverseResults = [...this.adverseResults, ...result].map((result) => {
                                    let json = result;
                                    json.api_all_data.sources = json.api_all_data.sources?.filter((source) => this.sources.includes(source) && source !== "ame");
                                    return json;
                                });
                            }

                            if ((!values[0]?.data?.data || values[0]?.data?.data?.length === 0) && (!values[1]?.data?.data || values[1]?.data?.data?.length === 0) && (!values[2]?.data?.data || values[2]?.data?.data?.length === 0) && (!values[3]?.data?.data || values[3]?.data?.data?.length === 0)) {
                                if (values[0]?.data?.message || values[1]?.data?.message || values[2]?.data?.message || values[3]?.data?.message) this.$toast.error(values[0]?.data?.message || values[1]?.data?.message || values[2]?.data?.message || values[3]?.data?.message);
                                else this.$toast.error("No data found");
                            }

                            ///// offshoreLeaks //////

                            // if (values[3]?.data?.category?.l ength > 0) {
                            //     this.offshore_entities = values[3]?.data?.category.map((e) => ({
                            //         entity_type: e.key,
                            //     }));
                            // }
                            this.adverseResults = this.adverseResults.map((ele, index) => ({...ele, curr_index: index}));
                            this.filterData();
                            this.adverseFilteredResults = this.adverseFilteredResults.map((result) => {
                                if (result.key === "dowJones") {
                                    const created_dates = [];
                                    const updated_dates = [];
                                    result.api_all_data?.sources?.forEach((src) => {
                                        if (src !== "ame") {
                                            if (result.api_all_data[src]["created_at"]) created_dates.push(new Date(result.api_all_data[src]["created_at"]));
                                            if (result.api_all_data[src]["updated_at"]) updated_dates.push(new Date(result.api_all_data[src]["updated_at"]));
                                        }
                                    });
                                    let created_date = created_dates.length ? Math.max(...created_dates) : "";
                                    let updated_date = updated_dates.length ? Math.max(...updated_dates) : "";
                                    return {
                                        ...result,
                                        ["created_at"]: created_date ? new Date(created_date).toISOString() : "",
                                        ["updated_at"]: updated_date ? new Date(updated_date).toISOString() : "",
                                    };
                                }
                                return result;
                            });
                            if (results[0]?.status === "rejected" || values[0]?.status === 500) {
                                this.showResult = true;
                                this.isLoading = false;
                                this.$toast.error(`LexisBridger: Something went wrong. Please try again.`);
                            }
                            if (results[1]?.status === "rejected" || values[1]?.status === 500) {
                                this.showResult = true;
                                this.isLoading = false;
                                this.$toast.error(`ComplyAdvantage: Something went wrong. Please try again.`);
                            }
                            if (results[2]?.status === "rejected" || values[2]?.status === 500) {
                                this.showResult = true;
                                this.isLoading = false;
                                this.$toast.error(`WorldCheck: Something went wrong. Please try again.`);
                            }
                            if (results[3]?.status === "rejected" || values[3]?.status === 500) {
                                this.showResult = true;
                                this.isLoading = false;
                                this.$toast.error(`DowJones: Something went wrong. Please try again.`);
                            }

                            this.sortResults();
                            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "lexis-bridger-tool"});
                            this.showResult = true;
                            // this.showSearch = false;
                            this.triggerRoute = true;
                            // if (this.adverseResults.length > 0) {
                            //     this.SET_ALL_TOOLS_DATA({ ...this._data, "tool-name": "lexis-bridger-tool" });
                            //     this.showResult = true;
                            //     // this.showSearch = false;
                            //     this.triggerRoute = true;
                            // } else {
                            //     this.$toast.error(`No data found`);
                            //     this.showSearch = true;
                            //     this.showResult = false;
                            // }
                            this.isLoading = false;
                            this.$emit("searchResult", {data: this.adverseResults, source: "lexis-bridger", searchType: "name"});
                            this.resetLoader("nameSearch");
                            this.$emit("getInitialQueryCount");
                        })
                        .catch((error) => {
                            this.$toast.error(`Something went wrong. Please try again.`);
                            this.showResult = true;
                            this.isLoading = false;
                            this.resetLoader("nameSearch");
                        });
                }
            } else {
                this.$toast.error("Please enter a valid input");
                return;
            }
        },

        /**
         * @function
         * @name setSearchedUser - run input query on tool and then handle green tick in the dropdown
         * @params {searchKeyword} - input search string to be searched
         */
        async setSearchedUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "lexis-bridger",
                val_type: this.entityType === "person" ? "name" : "company_name",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);

            if (this.entityType === "person") {
                let dataItem = this[`personSearches`][0]?.categories.find((dataItem) => {
                    return dataItem.value === searchKeyword;
                });
                if (dataItem) dataItem["visited"] = true;
                else if (data?.value) {
                    let list = searchKeyword.split(" ");
                    const first = list?.[0].trim();
                    const last = list?.[list.length - 1].trim();

                    list.splice(0, 1);
                    list.splice(list.length - 1, 1);
                    const middle = list.join(" ").trim();

                    if (first)
                        this[`fNameSearches`][0]?.categories.push({
                            ...data,
                            value: first,
                        });
                    if (last)
                        this[`lNameSearches`][0]?.categories.push({
                            ...data,
                            value: last,
                        });
                    if (middle)
                        this[`mNameSearches`][0]?.categories.push({
                            ...data,
                            value: middle,
                        });

                    this[`personSearches`][0]?.categories.push(data);
                    this.$emit("addCustomData", data);
                    this.personSearches[0].categories = uniqBy(this.personSearches?.[0]?.categories, "value");

                    this.fNameSearches[0].categories = uniqBy(this.fNameSearches?.[0]?.categories, "value");
                    this.mNameSearches[0].categories = uniqBy(this.mNameSearches?.[0]?.categories, "value");
                    this.lNameSearches[0].categories = uniqBy(this.lNameSearches?.[0]?.categories, "value");
                }
            } else {
                let dataItem = this[`nameSearches`][0]?.categories.find((dataItem) => {
                    return dataItem.value === searchKeyword;
                });
                if (dataItem) dataItem["visited"] = true;
                else if (data?.value) {
                    this[`nameSearches`][0]?.categories.push(data);
                    this.$emit("addCustomData", data);

                    this.nameSearches[0].categories = uniqBy(this.nameSearches?.[0]?.categories, "value");
                }
            }
            this.handleChecked(data);
            // }
        },

        /**
         * @function
         * @name redirectToQuery
         * @params {object} - dataRes - search filters data from the route to be autofill in the input fiels and filters
         */

        redirectToQuery(dataRes) {
            this.items_count = 10;
            this.offset = 0;
            this.limitPag = 1;
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                var {entity_type, person_entity, company_entity, additional_details, additional_info, ids, phones, addresses} = selected_query?.query;
                if (entity_type || additional_info || ids || phones || addresses) {
                    if (entity_type === "Individual") {
                        this.entityType = "person";
                        this.fName = {
                            name: person_entity.first_name,
                            value: person_entity.first_name,
                        };
                        this.mName = {
                            name: person_entity.middle_name,
                            value: person_entity.middle_name,
                        };
                        this.lName = {
                            name: person_entity.last_name,
                            value: person_entity.last_name,
                        };
                    } else {
                        this.entityType = "company";
                        this.selectedItem = {
                            name: company_entity.name,
                            value: company_entity.name,
                        };
                    }

                    if (ids) {
                        for (let index in ids) {
                            const type = ids[index].type;
                            const value = ids[index].number;
                            if (type === "National") this.nationalId.value = value;
                            else if (type === "DUNS") this.dunsId.value = value;
                            else this[type.toLowerCase()].value = value;
                        }
                    }

                    if (phones) {
                        for (let index in phones) {
                            const type = phones[index].type;
                            const value = phones[index].number;
                            this[type.toLowerCase()].value = value;
                        }
                    }

                    if (addresses) {
                        for (let index in addresses) {
                            const type = addresses[index].type;
                            let key;
                            if (type === "Current") key = "curr";
                            else if (type === "Previous") key = "prev";
                            else if (type === "Mailing") key = "mailing";
                            this[`${key}_address1`].value = addresses[index].street1;
                            this[`${key}_address2`].value = addresses[index].street2;
                            this[`${key}_city`].value = addresses[index].city;
                            this[`${key}_state`].value = addresses[index].stateprovincedistrict ? this[`${key}_state`].props.options?.find((opt) => opt.value === addresses[index].stateprovincedistrict) : null;
                            this[`${key}_postalCode`].value = addresses[index].postalcode;
                            this[`${key}_lexisCountry`].value = addresses[index].country ? this[`${key}_lexisCountry`].props.options?.[0].categories.find((opt) => opt.value === addresses[index].country) : null;
                        }
                    }

                    if (additional_info) {
                        for (let index in additional_info) {
                            const type = additional_info[index].type;
                            const value = additional_info[index].number || additional_info[index].value;
                            if (type === "Citizenship") this.citizenship.value = value ? this.citizenship.props.options?.[0].categories.find((opt) => opt.value === value) : null;
                            else if (type !== "DOB") this[type.toLowerCase()].value = value;
                            else {
                                this["lexisDateOfBirth"].drops[0].value = additional_info[index].date.date ? parseInt(additional_info[index].date.date, 10).toString() : "";
                                this["lexisDateOfBirth"].drops[1].value = additional_info[index].date.month ? this["lexisDateOfBirth"].drops[1].props.options[parseInt(additional_info[index].date.month, 10) - 1] : "";
                                this["lexisDateOfBirth"].drops[2].value = additional_info[index].date.year;
                            }
                        }
                    }
                    if (!dataRes) this.search();
                } else if (this.searchedClicked) {
                    if (!dataRes) this.search();
                    else this.redirectTo();
                }
            } else {
                this.redirectTo();
            }
        },

        loadMoreCompanies(pageNumber) {
            this.page_number = pageNumber;
        },

        async updateEntityType() {
            await this.resetFilter();
        },

        async mapInputFilters() {
            // this.lexisDateOfBirth.drops[0].value = "";
            // this.lexisDateOfBirth.drops[1].value = "";
            // this.lexisDateOfBirth.drops[2].value = "";
            for (let source in this.allSources) {
                let key = "filters";
                if (source === "lexisBridger") key = this.entityType === "person" ? "person_filters" : "company_filters";
                this.allSources[source].filterSections = this.allSources[source].filterSections.map((section) => ({
                    ...section,
                    filters: section[key].map((filter) => ({
                        ...this[filter],
                        // value: source === "lexisBridger" ? (this[filter].type === "string" ? "" : null) : "",
                        key: filter,
                    })),
                }));

                if (source === "lexisBridger") {
                    const phoneSection = this.allSources["lexisBridger"].filterSections.find((section) => section.label === "Phone Number");
                    if (this.entityType === "person")
                        phoneSection.filters = phoneSection.filters.map((filter, key) => ({
                            ...filter,
                            class: ["fax", "unknown"].includes(filter.label?.toLowerCase()) ? "custom-cols-2" : "custom-cols-4",
                        }));
                    else
                        phoneSection.filters = phoneSection.filters.map((filter) => ({
                            ...filter,
                            class: "custom-cols-4",
                        }));
                }
            }
        },

        /**
         * @function
         * @name initalizeData - Initalize inpput variables
         */
        async initalizeData() {
            this.allSources["lexisBridger"].isChecked = true;
            await this.mapInputFilters();
        },

        /**
         * @function
         * @name fillYears - Calculate and fill years dropdown with last 100 years
         */
        fillYears() {
            const now = new Date().getUTCFullYear();
            this.dayOptions = Array(31)
                .fill("")
                .map((i, idx) => idx + 1);
            (this.monthOptions = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]),
                (this.yearOfBirthOptions = Array(now - 1899)
                    .fill("")
                    .map((v, idx) => now - idx));
            this.yearOfBirth.props.options = this.yearOfBirthOptions;
            this.lexisDateOfBirth.drops[0].props.options = this.dayOptions;
            this.lexisDateOfBirth.drops[1].props.options = this.monthOptions;
            this.lexisDateOfBirth.drops[2].props.options = this.yearOfBirthOptions;
        },

        /**
         * @function
         * @name fetchAllJuridictions - get all jusridiction list and map in the jurisdiction dropdown
         */

        async fetchAllJuridictions() {
            const {data} = await axios.get("company/search/jurisdiction");
            const jurisdiction = data.data.map((el) => ({
                name: el.label,
                value: el.value,
            }));
            this.countriesOptions = [
                {
                    group: "Countries",
                    categories: jurisdiction,
                },
            ];
            this.country.props.options = this.countriesOptions;
            this.citizenship.props.options = this.countriesOptions;
            this.curr_lexisCountry.props.options = this.countriesOptions;
            this.prev_lexisCountry.props.options = this.countriesOptions;
            this.mailing_lexisCountry.props.options = this.countriesOptions;
            this.issuingCountry.props.options = this.countriesOptions;
        },

        /**
         * @function
         * @name fetchAllStates - get all states list and map in the states dropdown
         */

        async fetchAllStates() {
            const {data} = await axios.get("lexis_bridger/country_states");
            const states = data.map((el) => ({
                name: el.state,
                value: el.state,
            }));
            this.curr_state.props.options = states;
            this.prev_state.props.options = states;
            this.mailing_state.props.options = states;
        },

        onSelectOption(option) {
            this.order_by = option;
            this.manualSearch();
        },

        /**
         * @function
         * @name resetFilter - reset all search inputs and filters
         */
        async resetFilter() {
            // this.entityType = "person";
            this.isFilterApplied = false;
            this.peps = true;
            this.adverseMedia = true;
            this.sanction = true;
            this.checks = ["peps", "sanctions", "adverse"];
            this.sources = ["lexis"];
            for (let source in this.allSources) {
                this.allSources[source].isChecked = false;
            }
            if (this.allSources["djAme"]) this.allSources["djAme"].disabled = true;
            this.order_by = {
                name: "Updated Date",
                value: "updated_at",
            };
            this.fName = null;
            this.mName = null;
            this.lName = null;
            this.loaderController = {
                nameSearch: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            };
            this.request_id = "";
            this.lexis_request_id = "";
            this.selectedItem = null;
            this.searchKeyword = "";
            this.dropValue = "";
            this.entityCount = 1;
            this.queries = [];
            this.selectVal = [""];
            this.showResult = false;
            this.showFiltersPanel = false;
            this.adverseResults = [];
            this.adverseFilteredResults = [];
            this.searchQuery = "";
            this.sourceType = [];
            this.filterEntityType = [];
            this.filterTypes = [];
            this.filterJurisdiction = [];
            this.offshore_entities = [];
            this.icij_entity_type = [];
            this.items_count = 10;
            this.filters = {
                saved: "all",
                monitoring: "all",
            };
            this.isAllRunQueries = false;
            this.allQueriesParams = null;
            this.offsetPag = 0;
            this.limitPag = 1;
            this.isOldVersionSelected = false;
            this.timeline = [];
            for (let source in this.allSources) {
                for (const si in this.allSources[source].filterSections) {
                    const section = this.allSources[source].filterSections[si];
                    if (source === "lexisBridger") section.expanded = false;
                    for (const fi in section.filters) {
                        const filter = section.filters[fi];
                        if (filter.label === "DOB") {
                            this[filter.key].drops[0].value = "";
                            this[filter.key].drops[1].value = "";
                            this[filter.key].drops[2].value = "";
                        } else if (filter.type === "string") this[filter.key].value = "";
                        else if (filter.props && filter.props.options) this[filter.key].value = null;
                    }
                }
            }
            await this.initalizeData();
            this.$emit("updateMonitoringCascadingTimeline", {
                timeline: this.timeline,
                meta_data: null,
            });
            this.redirectTo();
            this.$forceUpdate();
        },

        closeExpandedResult() {
            this.isResultExpanded = false;
            this.selectedResultCard = null;
            this.isOldVersionSelected = false;
        },
        resetPage() {
            this.page_number = 1;
            this.closeExpandedResult();
        },
        async handlePageChange(page_number) {
            if (this.$route.name === "monitoring" && this.getTotalPages - 1 <= this.page_number && this.adverseResults.length >= this.items_count * this.page_number && this.latestVersionTotalResults != this.adverseResults.length) {
                this.offsetPag += 1;
                this.limitPag += 1;
                this.manualSearch();
            }
            this.loadMoreCompanies(page_number);
            this.closeExpandedResult();
            this.scrollToTop();
            if (this.isAllRunQueries) {
                if (this.getTotalPages - 1 <= this.page_number && this.adverseResults.length >= this.items_count * this.page_number) {
                    await this.onRunAllQueries(null, "adverseResults");
                }
            }
        },
        scrollToTop() {
            // document.getElementById("adverse-media").scrollTo({
            //     top: "0px",
            //     behavior: "smooth",
            // });
            // document.getElementById("result-list-data").scrollTo({
            //     top: 1,
            //     behavior: "smooth",
            // });
            document.querySelector("section#result-list").scrollTop = 0;
        },

        /**
         * @function
         * @name clearFilters - function to clear output filters
         */
        async clearFilters() {
            this.page_number = 1;
            this.sourceType = [];
            this.searchQuery = "";
            this.filterEntityType = [];
            this.isFilterApplied = false;
            this.filterTypes = [];
            this.filterJurisdiction = [];
            this.filters = {
                saved: "all",
                monitoring: "all",
            };
            this.offshore_entities = [];
            this.icij_entity_type = [];
            this.closeExpandedResult();
            await this.onApplyFilter();
            this.sortResults();
        },

        setPersonName() {
            let list = [];
            list.push(this.fName?.value);
            list.push(this.mName?.value);
            list.push(this.lName?.value);
            this.searchKeyword = list.filter((el) => el).join(" ");
        },

        setCompanyName() {
            this.searchKeyword = this.selectedItem?.value ?? "";
        },

        onInput(value, field) {
            if (value)
                this[field] = {
                    name: value,
                    value: value,
                };
            else this[field] = null;
        },

        closeDropdown(ref, field) {
            this.$refs[ref].search = field?.value ?? "";
        },

        /**
         * @function
         * @name cancelUpload - function to cancel the file uploader
         */

        cancelUpload() {
            this.$refs["uploadBtn"].cancelUpload();
        },
        resultListStyles() {
            // isResultExpanded && 'width:40% !important;' && isMonitoringTab ? 'max-height: calc(100% - 202px)' : 'max-height: calc(100% - 128px)'
            let styles = "";
            if (this.isQueryPanelExpanded && this.isResultExpanded) styles += "width:30% !important ;";
            else if (this.isResultExpanded && !this.isQueryPanelExpanded) styles += "width:40% !important;";
            return styles;
        },
        // isResultExpanded ? 'width:60% !important;' : $route.name == 'monitoring' ? ' max-height: calc(100% - 240px)!important; ' : ' max-height: calc(100% - 140px)!important;'
        getStyles() {
            let styles = " ";
            if (this.isQueryPanelExpanded && this.isResultExpanded) styles += "width:70% !important ;";
            else if (this.isResultExpanded && !this.isQueryPanelExpanded) styles += "width:60% !important;";
            // if (this.$route.name == "monitoring") styles += " max-height: 100% - 100px)!important; ";
            // else styles += " max-height: 100% !important; ";
            return styles;
        },

        /**
         * @function
         * @name handleMarkAsAllDone
         * @params {e} - pointer event for Monitoring - mark all done input checkbox
         */
        async handleMarkAsAllDone(event) {
            const visited = event.target.checked;
            let doc_id = [];
            let payload;
            this.adverseResultsList.slice(this.items_count * (this.page_number - 1), this.page_number * this.items_count).forEach((advRes, index) => {
                // doc_id.push(advRes.api_all_data.monitoring_id);
                let sources = [];
                sources.push(advRes.api_all_data.doc_source),
                    (payload = {
                        doc_id: advRes.api_all_data.monitoring_id,
                        sources: sources,
                        cascaded: advRes.api_all_data?.history_count ? true : false,
                    });
                doc_id.push(payload);
                this.adverseResults = this.adverseResults.map((res) => {
                    let obj = res;
                    if (advRes.curr_index === res.curr_index) {
                        obj.api_all_data.changes_viewed = visited;
                    }
                    return obj;
                });
            });
            if (this.timeline.length > 0) {
                this.timeline = this.timeline.map((res) => {
                    let obj = res;
                    obj.api_all_data.changes_viewed = visited;
                    return obj;
                });
            }
            this.filterData();
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                card_info: doc_id,
                case_id: this.getCaseId,
                visited,
                // query_id: this.getSelectedToolQuery.query_id,
            });
        },

        /**
         * @function
         * @name isSourceAuth
         * @params {String} src - selected source (comply_advantage / world_check / dow_jones/ offshoreleaks)
         */
        isSourceAuth(src) {
            if (src) return this.getPermissions.includes(`${src}__adverse_pep/sacntion`) || this.getPermissions.includes(`${src}__adverse_pep_sacntion`);
            else return true;
        },

        /**
         * @function
         * @name isSourceSpecificFilter
         * @params {String} src - selected source (comply_advantage / world_check / dow_jones/ offshoreleaks)
         */
        isSourceSpecificFilter(src) {
            return this.allSources[src].filterSections && this.allSources[src].filterSections.length && this.allSources[src].filterSections.some((section) => section.filters.some(filter.isSourceSpecific));
        },

        /**
         * @function
         * @name handleFilterApplied
         * @params {Object} filter - selected filter
         */
        async handleFilterApplied(filter) {
            let match = -1;
            switch (filter.name) {
                case "sourceType":
                    match = this[filter.name].findIndex((el) => el.name === filter.value.name);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filterEntityType":
                    match = this[filter.name].findIndex((el) => el === filter.value);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filterTypes":
                    match = this[filter.name].findIndex((el) => el.label === filter.value.label);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filterJurisdiction":
                    match = this[filter.name].findIndex((el) => el === filter.value);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filters.monitoring":
                    this.filters.monitoring = "all";
                    break;
                case "filters.saved":
                    this.filters.saved = "all";
                    break;
            }
            this.page_number = 1;
            await this.onApplyFilter();
            this.sortResults();
        },

        /**
         * @function
         * @name parseValue
         * @params {Object} value - selected filter value
         */
        parseValue(value) {
            return typeof value === "string" ? value : value.label || value.name || value.value;
        },

        /**
         * @function
         * @name toggleAllSources - toggle selection of all sources
         */
        toggleAllSources() {
            if (!this.allSourcesChecked) {
                for (let source in this.allSources) this.allSources[source].isChecked = true;
                this.sources = ["complyadvantage", "worldcheck", "wl", "soe", "lexis"];
            } else {
                for (let source in this.allSources) this.allSources[source].isChecked = false;
                this.sources = [];
            }
        },

        /**
         * @function
         * @name sortResults
         */
        sortResults() {
            this.adverseFilteredResults = [
                ...this.adverseFilteredResults
                    .filter((el) => el[this.order_by.value])
                    ?.sort((a, b) => {
                        if (a.key !== "lexisBridger") {
                            let diff = new Date(a[this.order_by.value]) - new Date(b[this.order_by.value]);
                            if (diff > 0) return -1;
                            else return 1;
                        }
                    }),
                ...this.adverseFilteredResults.filter((el) => !el[this.order_by.value]),
            ];
        },
        async onApplyFilter() {
            this.isFilterApplied = true;
            const {monitoring, saved} = this.prevAppliedFilters;
            const {monitoring: fil_monitoring, saved: fil_saved} = this.filters;

            if (fil_monitoring != monitoring || fil_saved != saved) {
                if (this.isAllRunQueries) {
                    this.offsetPag = 0;
                    this.limitPag = 1;
                    this.isNextPag = true;
                    this.adverseResults = [];
                    await this.onRunAllQueries(null, "adverseResults");
                } else await this.search();
            }
            this.filterData();
            this.setPrevFilters();
            this.$forceUpdate();
            // await this.getToolsStatus();
        },
        async onBackToSearch() {
            if (this.allSources["djAme"]) this.allSources["djAme"].disabled = true;
            this.order_by = {
                name: "Updated Date",
                value: "updated_at",
            };
            this.request_id = null;
            this.lexis_request_id = null;
            this.entityCount = 1;
            this.queries = [];
            this.selectVal = [""];
            this.showResult = false;
            this.showFiltersPanel = false;
            this.isFilterApplied = false;
            this.searchQuery = "";
            this.sourceType = [];
            this.filterEntityType = [];
            this.filterTypes = [];
            this.filterJurisdiction = [];
            this.items_count = 10;

            this.filters = {
                saved: "all",
                monitoring: "all",
            };
            this.isAllRunQueries = false;
            this.allQueriesParams = null;
            this.offsetPag = 0;
            this.limitPag = 1;
            this.isExpandedLoaded = false;
            this.icij_entity_type = [];
            this.resetLoader("nameSearch");
            this.setPrevFilters(this.filters);
            this.setToolsFilterData(this.filters);
            await this.getToolsStatus();
            this.$router.push({path: this.$route.path}).catch((err) => {
                console.error(err);
            });
            for (let source in this.allSources) {
                if (source === "lexisBridger") {
                    for (const si in this.allSources[source].filterSections) {
                        const section = this.allSources[source].filterSections[si];
                        section.expanded = true;
                    }
                }
            }
            this.$forceUpdate();
        },
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
        setPrevFilters() {
            let {monitoring, saved} = this.filters;
            this.prevAppliedFilters = {
                monitoring,
                saved,
                sourceType: this.sourceType,
                searchQuery: this.searchQuery,
                filterEntityType: this.filterEntityType,
                filterTypes: this.filterTypes,
                filterJurisdiction: this.filterJurisdiction,
            };
        },
        async onPerPageChange() {
            this.page_number = 1;
            if (this.adverseResults.length <= this.items_count) {
                if (this.isAllRunQueries) {
                    this.adverseResults = [];
                    this.offsetPag = 0;
                    this.limitPag = 1;
                    await this.onRunAllQueries(null, "adverseResults");
                    if (this.adverseResults.length <= this.items_count) {
                        this.offsetPag = 1;
                        this.limitPag = 2;
                        await this.onRunAllQueries(null, "adverseResults");
                    }
                } else {
                    this.manualSearch();
                    if (this.latestVersionTotalResults > 100) {
                        this.offsetPag += 1;
                        this.limitPag += 1;
                        await this.manualSearch();
                    }
                }
                this.scrollToTop();
            }
        },

        async handleSearch() {
            if (!this.isSearchBtnDisabled) {
                let globalFilters = false;
                globalFilters = await this.checkGlobalFilters();
                if (!globalFilters) {
                    await this.manualSearch();
                    await this.resetQueryFilter();
                }
            }
        },

        async checkGlobalFilters() {
            if (this.nerFilters) {
                try {
                    const confirm = await this.$refs["modal-confirm"].show({
                        title: "Reset Global Filters",
                        message: "There are some Global Filters applied. Are you sure you want to reset all Global Filters ?",
                        autoclose: false,
                    });
                    if (confirm) {
                        this.$emit("handleNerClear");
                        this.$refs["modal-confirm"].close();
                        return false;
                    } else return true;
                } catch (error) {
                    this.$refs["modal-confirm"].close();
                }
            } else return false;
        },

        async showMonitoringHistory(data) {
            const payload = data.payload;
            let source = [];
            if (this.sources?.length > 0) {
                source = this.sources;
            }
            if (payload.monitoringHistoryIndex) {
                this.timeline = [];

                const values = await monitoringHistoryData(payload, source);
                if (values?.length > 0) {
                    let world_check = this.getMappingValue(
                        values.filter((val) => val.doc_source === "worldcheck"),
                        "worldCheck",
                        ADVERSE_TOOL_MAPPING
                    );
                    let comply_advantage = this.getMappingValue(
                        values.filter((val) => val.doc_source === "complyadvantage"),
                        "complyAdvantage",
                        ADVERSE_TOOL_MAPPING
                    );
                    let dow_jones = this.getMappingValue(
                        values.filter((val) => val.doc_source === "dowjones"),
                        "dowJones",
                        ADVERSE_TOOL_MAPPING
                    );
                    let offshore_leaks = this.getMappingValue(
                        values.filter((val) => val.doc_source === "offshoreleaks"),
                        "offshoreLeaks",
                        ADVERSE_TOOL_MAPPING
                    );
                    this.timeline = [...this.timeline, ...world_check, ...comply_advantage, ...dow_jones, ...offshore_leaks];
                    this.timeline = this.timeline.map((ele, index) => ({...ele, curr_index: index, isOldVersion: true}));
                }
                const parent = {
                    ...data.detail,
                    is_parent: true,
                };
                const meta_data = {};
                this.$emit("updateMonitoringCascadingTimeline", {
                    timeline: cloneDeep(this.timeline)
                        .reverse()
                        .concat([parent]),
                    meta_data: meta_data,
                });
            } else {
                this.timeline = [];
                this.$emit("updateMonitoringCascadingTimeline", {
                    timeline: this.timeline,
                    meta_data: null,
                });
            }
        },

        updateComparisonReferences(details) {
            if (this.isComparisonModeOn) {
                const isOldVersion = details.isOldVersion;
                if (isOldVersion) {
                    this.$emit("updateMonitoringCascadingTimeline", {
                        newData: details,
                    });
                } else {
                    const parent = {
                        ...details,
                        is_parent: true,
                    };
                    this.$emit("updateMonitoringCascadingTimeline", {
                        newData: parent,
                    });
                }
            }
        },

        makeDataForGraph(data, source) {
            const payload = {
                tool_data:
                    data?.map((el) => ({
                        doc: el.doc,
                        card_name: el.doc.name,
                    })) ?? [],
                extra_fields: {
                    search_string: "",
                    query_data: {
                        entity_type: "",
                    },
                    source: source,
                },
                case_id: this.case_id || "",
            };

            return payload;
        },

        getAddress(address) {
            if (address) {
                if (Array.isArray(address)) return address;
                else {
                    let obj = [address];
                    return obj;
                }
            } else return [];
        },

        isInvalid(value, type) {
            let valid = true;
            if (type === "name") {
                var pattern = /^[a-zA-Z-"]+$/;
                valid = pattern.test(value);
            } else if (type === "ein") {
                var pattern = /[0-9]{2}-[0-9]{7}$/;
                valid = pattern.test(value);
            } else if (type === "ssn") {
                var pattern = /[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
                valid = pattern.test(value);
            } else if (["business", "home", "work", "cell", "fax", "unknown"].includes(type)) {
                var pattern = /^[0-9]+$/;
                valid = pattern.test(value) && value.length <= 25;
            } else if (type === "dob") {
                if (this.entityType === "person") {
                    const filter = this.allSources["lexisBridger"].filterSections?.[0].filters?.[0];
                    const date = filter.drops?.[0].value;
                    const month = filter.drops?.[1].value;
                    const year = filter.drops?.[2].value;
                    if (date && month && year) return new Date(`${month}-${date}-${year}`) < new Date() ? false : true;
                    else return false;
                } else return false;
            }
            return value && !valid;
        },

        errorMessage(type) {
            let message = "";
            if (type === "ein") message = "EIN should be numeric in the format : XX-XXXXXXX";
            else if (type === "ssn") message = "SSN should be numeric in the format : XXX-XX-XXXX";
            else message = "Not an allowed format";
            return message;
        },
    },
};
